import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import * as fontRegular from '@fortawesome/free-regular-svg-icons';
import * as fontSolid from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'bw-tournament-header',
  templateUrl: './tournament-header.component.html',
  styleUrls: ['./tournament-header.component.scss']
})
export class TournamentHeaderComponent implements OnInit {
  public fontSolid = fontSolid;
  public fontRegular = fontRegular;

  @Output() public save = new EventEmitter<void>();
  @Output() public cancel = new EventEmitter<void>();
  @Output() public saveExit = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {

  }
}
