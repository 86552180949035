import {
    Component,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef,
    AfterViewInit
}                            from '@angular/core';
import {ValuedComponentBase} from '../../component-base/valued-component-base';

@Component({
    selector: 'gx-bool-slider',
    templateUrl: './gx-bool-slider.component.html',
    styleUrls: ['./gx-bool-slider.component.scss']
})
export class GxBoolSliderComponent extends ValuedComponentBase<boolean> implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('input')
    input?: ElementRef;

    private inputElement?: HTMLInputElement;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.valueSet && this.inputElement) {
            this.inputElement.value = changes.valueSet.currentValue;
        }
    }

    ngAfterViewInit() {
        this.inputElement = this.input!.nativeElement;
        this.inputElement!.checked = !!this.value;
    }

    public updateBoolValue(evt: Event) {
        const slider: HTMLInputElement = evt.target! as unknown as HTMLInputElement;
        this.value = slider.checked;
        this.valueChange.emit(this.value);
    }
}
