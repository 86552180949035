import {NgModule}             from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import * as components        from './components';
import {UsageContext}         from './core/constants/usage-context';

const routes: Routes = [
    {path: '', component: components.LoginComponent},
    {path: 'loading', component: components.LoadingComponent},
    {path: 'home', component: components.OwnerHomeComponent},
    {
        path: 'register/:tournamentId/:step',
        component: components.TournamentRegistrationComponent,
        data: {usageContext: UsageContext.usageContextUser}
    }, {
        path: 'administration',
        component: components.AdministrationComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }, {
        path: 'administration/:tournamentId/edit-eligibility',
        component: components.EditEligibilityComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }, {
        path: 'administration/file-manager',
        component: components.FileManagerComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }, {
        path: 'administration/file-manager/:folder',
        component: components.FileManagerComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }, {
        path: 'administration/:tournamentId/edit-tournament-waiver',
        component: components.EditTournamentWaiverComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }, {
        path: 'administration/:tournamentId/edit-tournament',
        component: components.EditTournamentComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }, {
        path: 'administration/:tournamentId/edit-calcutta-eligibility',
        component: components.EditCalcuttaEligibilityComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }, {
        path: 'admin/register/:userId/:tournamentId/:step',
        component: components.TournamentRegistrationComponent,
        data: {usageContext: UsageContext.usageContextStaff}
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
