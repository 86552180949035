<div *ngIf="tournament">
    <bw-administration-header>
        Edit Tournament Waiver
    </bw-administration-header>
    <main>
        <h2>Tournament: {{ tournament.Description}}</h2>
        <hr/>
        <br/>

        <b>
            Ask "Will your team be participating in the Take a Hero Fishing Day?" Question during Registration?
        </b>

        <gx-bool-slider
                label='Ask "Will your team be participating in the Take a Hero Fishing Day?" Question during Registration?'
                [(value)]="takeAHero"
                [required]="true"
        ></gx-bool-slider>

    </main>
    <footer>
        <gx-icon-button
                [icon]="fontSolid.faDoorClosed"
                text="Save and Close"
                (buttonClick)="close()"
        ></gx-icon-button>
    </footer>
</div>
