import {Component, NgZone, OnInit}                                                   from '@angular/core';
import {LookupService, TournamentService}                                            from '@bluewater/services';
import {ActivatedRoute}                                                              from '@angular/router';
import {ComponentBase, Integer}                                                      from '@gx/core';
import {distinct}                                                                    from 'rxjs/operators';
import {EligibilityViewModel, TournamentEligibilityUpdateModel, TournamentViewModel} from '@bluewater/viewmodels';
import {fontRegular, fontSolid, switchToLegacyApp}                                   from '../../../modules';
import {sleepAsync}                                                                  from '../../../core/functions';

@Component({
    selector: 'bw-edit-eligibility',
    templateUrl: './edit-eligibility.component.html',
    styleUrls: ['./edit-eligibility.component.scss']
})
export class EditEligibilityComponent extends ComponentBase implements OnInit {
    public tournamentId = 0;
    public buttonsEnabled = true;
    public eligibilityList: EligibilityViewModel[] = [];
    public tournament?: TournamentViewModel;
    public selectedItems: { [key: string]: boolean } = {};
    public fontSolid = fontSolid;
    public fontRegular = fontRegular;

    constructor(private tournamentService: TournamentService,
                private lookupService: LookupService,
                private route: ActivatedRoute,
                private zone: NgZone) {
        super();

        this.takeUntilDestroyed(this.route.params)
            .pipe(distinct())
            .subscribe(params => {
                this.tournamentId = +params.tournamentId;
                this.fetchTournamentAsync(this.tournamentId);
                this.fetchEligibilityListAsync();
            });
    }

    ngOnInit(): void {

    }

    private async fetchTournamentAsync(tournamentId: Integer) {
        const tournament = await this.tournamentService.getTournamentAsync(this.tournamentId, true);
        const selectedItems: { [key: number]: boolean } = {};
        tournament.Eligibilities.forEach(el => selectedItems[el.EligibilityId] = true);

        this.zone.run(() => {
            this.tournament = tournament;
            this.selectedItems = selectedItems;
        });
    }

    private async fetchEligibilityListAsync() {
        const eligibilityList = await this.lookupService.getEligibilityListAsync();

        this.zone.run(() => {
            this.eligibilityList = eligibilityList;
        });
    }

    public async close() {
        await this.saveAsync();
        switchToLegacyApp();
    }

    public async saveAsync(): Promise<void> {
        this.zone.run(() => this.buttonsEnabled = false);

        const items = this.selectedItems;

        const selectedItemsList: TournamentEligibilityUpdateModel[] = this.eligibilityList
            .map(el => ({
                EligibilityId: el.EligibilityId,
                Enabled: items[el.EligibilityId]
            }));

        await this.tournamentService.saveTournamentEligibilityAsync(this.tournamentId, selectedItemsList);

        this.zone.run(() => this.buttonsEnabled = true);
    }


    public updateSelectedItems(eligibilityId: Integer, event: Event) {
        const target = <HTMLInputElement> event.target;
        this.selectedItems[eligibilityId] = target.checked;
    }
}
