<div *ngIf="tournament">
    <bw-administration-header>
        Edit Tournament Calcutta Eligibility
    </bw-administration-header>
    <main>
        <div>
            <h2>Tournament: {{ tournament.Description}}</h2>
            <br/>
            <br/>
            <table>
                <thead>
                <tr>
                    <th>Calcutta Name</th>
                    <th>Eligibility Required</th>
                </tr>
                </thead>
                <tr *ngFor="let calcutta of tournamentCalcuttas">
                    <td>{{ calcutta.Name }}</td>
                    <td>
                        <select (change)="updateRequiredEligibility(calcutta.CalcuttaProductId, $event)">
                            <option>No Eligibility Required</option>
                            <option *ngFor="let elig of tournament.Eligibilities"
                                    [selected]="calcutta.EligibilityId === elig.EligibilityId"
                                    [value]="elig.EligibilityId"

                            >
                                {{ elig.Name}}
                            </option>
                        </select>
                    </td>
                    <td [ngClass]="{saving: calcutta.updateStatus === calcuttaChangeStatus.Saving, saved: calcutta.updateStatus === calcuttaChangeStatus.Saved }"
                        *ngIf="calcutta.updateStatus && calcutta.updateStatus !== calcuttaChangeStatus.Unchanged">
                        {{calcutta.updateStatus}}
                    </td>
                </tr>
            </table>

        </div>
    </main>
    <footer>
            <gx-icon-button
                    [icon]="fontSolid.faDoorClosed"
                    text="Close"
                    (buttonClick)="close()"
            ></gx-icon-button>
    </footer>
</div>
