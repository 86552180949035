import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { TeamEligibilityViewModel, TeamViewModel } from '@bluewater/viewmodels';
import { ValuedComponentBase } from '../../../core/component-base/valued-component-base';
import { TournamentService } from '../../../services/tournament.service';
import { SelectedEligibilityViewModel } from '../../../view-models/SelectedEligibilityViewModel';
import { RegistrationService } from '@bluewater/services';
import { toYesNo } from '../../../core/functions';
import { YesNo } from '@gx/core';
import { ValidationMessage } from '../anglers/extensions/validation.message';

@Component({
    selector: 'bw-eligibility',
    templateUrl: './eligibility.component.html',
    styleUrls: ['./eligibility.component.scss']
})
export class EligibilityComponent extends ValuedComponentBase<TeamViewModel> implements OnInit {
    @Input() tournamentName = '';
    public validationMessages: ValidationMessage[] = [];
    public eligibilities: SelectedEligibilityViewModel[] = [];
    private isLoaded = false;

    constructor(
        private tournamentService: TournamentService,
        private registrationService: RegistrationService,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef
    ) {
        super();

        this.validate = team => this.checkIsValid(team);
    }

    ngOnInit(): void {
        this.onInitAsync();
    }

    private checkSmallBoatEligibilityValid(team: TeamViewModel | null | undefined) {
        function matchesSmallBoatPattern(value: string) {
            return (/.*Small Boat.*/i).test(value);
        }

        let isValid = true;

        // hacky.  expects the description to include the words "Small Boat" and the length "xx feet"
        const teamView = team!;
        const smallBoatEligibilities = this.eligibilities.filter(el => el.Answer === YesNo.Yes && matchesSmallBoatPattern(el.Name));

        for (const el of smallBoatEligibilities) {
            const matches = /([\d][\d]) feet/.exec(el.Description) || [-1, -1];
            const maxBoatLength = +matches[1];

            if (teamView.BoatLength && +teamView.BoatLength > maxBoatLength) {
                this.validationMessages.push({
                    id: 'invalid-small-boat',
                    message: 'Boat Length over ' + maxBoatLength + ' does not qualify for Small Boat',
                    subMessages: []
                });

                isValid = false;
            }
        }

        return isValid;
    }

    private checkIsValid(team: TeamViewModel | null | undefined): boolean {
        // reset validation messages
        this.validationMessages = [];

        // this is a two-off, special validation
        if (!this.checkSmallBoatEligibilityValid(team)) {
            return false;
        }
        
        return !!this.value!.EligibilitySignature && !this.eligibilities.some(el => !el.Answer);
    }

    private async onInitAsync(): Promise<void> {
        const team = this.value!;

        const tournamentId = team.TournamentId;
        const tournament = await this.tournamentService.getTournamentAsync(tournamentId);
        const teamEligibilities = team.Eligibilities;

        const eligibilities: SelectedEligibilityViewModel[] = tournament
            .Eligibilities
            .map(el => ({
                EligibilityId: el.EligibilityId,
                Description: el.Description,
                Name: el.Name,
                Answer: toYesNo(teamEligibilities, el.EligibilityId)
            }));

        this.ngZone.run(() => this.eligibilities = eligibilities);
        this.isLoaded = true;
        this.cdr.detectChanges();
    }

    public updateValue() {
        if (this.isLoaded) {
            const teamEligibilities: TeamEligibilityViewModel[] = this.eligibilities
                .map(el => ({
                    EligibilityId: el.EligibilityId,
                    Answer: el.Answer!
                }))
                .filter(el => el.Answer !== undefined);

            this.value!.Eligibilities = teamEligibilities;
        }
        super.updateValue();
    }
}
