import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { httpGet, httpPost, httpPatch, httpPut, httpDelete } from './functions';
import {
    ApplicationUserViewModel,
    CreditCardPayment,
    FileUploadUpdateModel,
    ManualPaymentUpdateModel,
    TeamUpdateModel,
    TournamentEligibilityUpdateModel
} from '@bluewater/viewmodels';
import { Integer } from '@gx/core';
import { HttpErrorHandlerService } from '../http-error-handler.service';


interface UsernamePassword {
    username: string;
    password: string;
}

@Injectable({
    providedIn: 'root'
})
export class ApiCommandService {
    constructor(
        private httpErrorHandlerService: HttpErrorHandlerService,
        private http: HttpClient
    ) {
    }

    private get<T>(relativeUrl: string, throwOnException?: boolean): Promise<T> {
        return this.httpErrorHandlerService.popWarningOnExceptionAsync(
            httpGet<T>(this.http, relativeUrl),
            throwOnException
        );
    }

    private delete<T>(relativeUrl: string, throwOnException?: boolean): Promise<T> {
        return this.httpErrorHandlerService.popWarningOnExceptionAsync(
            httpDelete<T>(this.http, relativeUrl),
            throwOnException
        );
    }

    private post<TBody, TReturn>(relativeUrl: string, body: TBody, throwOnException?: boolean): Promise<TReturn> {
        return this.httpErrorHandlerService.popWarningOnExceptionAsync(
            httpPost<TReturn>(this.http, relativeUrl, body),
            throwOnException
        );
    }

    private put<TBody, TReturn>(relativeUrl: string, body: TBody, throwOnException?: boolean): Promise<TReturn> {
        return this.httpErrorHandlerService.popWarningOnExceptionAsync(
            httpPut<TReturn>(this.http, relativeUrl, body),
            throwOnException
        );
    }

    private patch<TBody, TReturn>(relativeUrl: string, body: TBody, throwOnException?: boolean): Promise<TReturn> {
        return this.httpErrorHandlerService.popWarningOnExceptionAsync(
            httpPatch<TReturn>(this.http, relativeUrl, body),
            throwOnException
        );
    }

    public authenticateUserAsync(username: string, password: string): Promise<ApplicationUserViewModel> {
        return this.post<UsernamePassword, ApplicationUserViewModel>('/Authentication', { username, password });
    }

    public logoutUserAsync(): Promise<void> {
        return this.post<{}, void>('/Authentication/Logout', {});
    }

    public createUserAsync(username: string, password?: string): Promise<string> {
        const encodedPassword = password ? encodeURIComponent(password) : '';
        return this.get<string>('/Authentication/CreateUser?username=' + encodeURIComponent(username) + '&password=' + encodedPassword);
    }

    public changePassword(password: string) {
        return this.get<string>('/Authentication/ChangePassword?password=' + encodeURIComponent(password));
    }

    public saveTournamentEligibilityAsync(tournamentId: Integer, eligibilityIdList: TournamentEligibilityUpdateModel[]): Promise<void> {
        return this.post<TournamentEligibilityUpdateModel[], void>('/Tournament/' + tournamentId + '/Eligibility', eligibilityIdList);
    }

    public async saveTeamAsync(registration: TeamUpdateModel): Promise<void> {
        return this.post<TeamUpdateModel, void>('/Team', registration);
    }

    public async updateTeamOrder(teamId: Integer): Promise<void> {
        return this.get<void>('/Team/Order/' + teamId);
    }

    public async applyCreditCardPayment(creditCardPayment: CreditCardPayment, throwOnException?: boolean): Promise<void> {
        return this.post<CreditCardPayment, void>(`/Team/Order/${creditCardPayment.OrderId}/CreditCardPayment`, creditCardPayment, throwOnException);
    }

    public applyManualPayment(manualPayment: ManualPaymentUpdateModel, throwOnException?: boolean): Promise<void> {
        return this.post<ManualPaymentUpdateModel, void>(`/Team/Order/${manualPayment.OrderId}/ManualPayment`, manualPayment, throwOnException);
    }

    public ignorePreviousAngler(teamId: number, anglerId: number): Promise<void> {
        return this.post<void, void>(`/Team/${teamId}/ignore-copyable-angler/${anglerId}`, undefined);
    }

    public administrativeSave(teamId: number, values: { isComplete?: boolean, boatNumber?: number }): Promise<void> {
        return this.patch(`/Team/${teamId}/adminSave`, values, true);
    }

    public saveTournamentCalcuttaEligibilityAsync(
        tournamentId: Integer,
        calcuttaProductId: Integer,
        eligibilityId?: Integer): Promise<void> {

        return this.post(`/Tournament/${tournamentId}/tournamentCalcuttaEligibility?calcuttaProductId=${calcuttaProductId}&eligibilityId=${eligibilityId}`, {}, true);
    }

    public async createFolderAsync(folder: string) {
        return this.post<null, void>(`/Storage/${folder}/create`, null);
    }

    public async uploadFileAsync(folder: string, name: string, fileUri: string) {
        const fileUploadData: FileUploadUpdateModel = {
            Name: name,
            Base64EncodedFile: fileUri
        };
        return this.post<FileUploadUpdateModel, void>(`/Storage/${folder}`, fileUploadData);
    }

    public async deleteFileAsync(folder: string, filename: string) {
        return this.delete<void>(`/Storage/${folder}/${filename}`, true);
    }

    public async updateTournamentWaiverAsync(tournamentId: number, selectedWaiver: string) {
        return this.post<{ url: string }, void>(`/Tournament/${tournamentId}/selectWaiver`, { url: selectedWaiver });
    }

    public async updateTournamentTakeAHero(tournamentId: number, takeAHero: boolean) {
        return this.post<{}, void>(`/Tournament/${tournamentId}/takeAHero?value=` + takeAHero, {});
    }
}
