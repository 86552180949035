import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'gx-theme-card',
    templateUrl: './gx-theme-card.component.html',
    styleUrls: ['./gx-theme-card.component.scss']
})
export class GxThemeCardComponent implements OnInit {
    @Input() title?: string;
    @Input() actionTitle?: string;
    @Output() onAction = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    public onActionClick() {
        this.onAction.next();
    }
}
