<div *ngIf="value && tournament">
    <bw-step-header
            stepName="Calcuttas"
            [stepNumber]="4"
            [totalStepCount]="5"
            [tournamentName]="tournamentName"
    >
    </bw-step-header>

    <div class="column-collection">
        <div class="row-collection">
            <div class="calcutta-select">Select Calcuttas</div>
            <div class="calcutta-name">Name</div>
            <div class="calcutta-price">Price</div>
            <div class="calcutta-paid">Paid?</div>
        </div>
        <div (click)="flip(calcutta.tournamentCalcutta.CalcuttaProductId)"
             [ngClass]="{'calcutta-row': true, 'row-collection': true, selected: selectedCalcuttas[calcutta.tournamentCalcutta.CalcuttaProductId] }"
             *ngFor="let calcutta of calcuttaViews">
            <div class="calcutta-select">
                <fa-icon class="selected-icon" [icon]="fontSolid.faCheck"></fa-icon>
                <fa-icon class="unselected-icon" [icon]="fontRegular.faSquare"></fa-icon>
            </div>
            <div class="calcutta-name">{{ calcutta.tournamentCalcutta.Name }}</div>
            <div class="calcutta-price">{{ calcutta.tournamentCalcutta.Price | currency }}</div>
            <div class="calcutta-paid">{{ calcutta.teamCalcutta?.IsPaid ? 'Paid' : 'Unpaid' }}</div>
        </div>
    </div>
</div>
