import {Component, Input, NgZone, OnChanges, OnInit} from '@angular/core';
import {ValuedComponentBase}                         from '../../../../core/component-base/valued-component-base';
import {ApplicationUserViewModel, TeamViewModel}     from '@bluewater/viewmodels';
import {AuthenticationService, RegistrationService}  from '@bluewater/services';
import {GuidString}                                  from '@gx/core';
import {Router}                                      from '@angular/router';

const emailAddressRegex = /.*@.*\..*/;

enum ResultType {
    CreateUser = 'create-user',
    ExistingUser = 'existing-user',
    ExistingRegistration = 'existing-registration',
}

type SelectUserSearchResult = ApplicationUserViewModel & { ResultType: ResultType };

@Component({
    selector: 'bw-select-user',
    templateUrl: './select-user.component.html',
    styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent extends ValuedComponentBase<TeamViewModel> implements OnInit, OnChanges {
    @Input() public tournamentName = '';
    @Input() public tournamentId = 0;

    public searchResults: SelectUserSearchResult[] = [];
    public resultType = ResultType;

    constructor(
        private authenticationService: AuthenticationService,
        private registrationService: RegistrationService,
        private router: Router,
        private zone: NgZone
    ) {
        super();
        this.validate = () => false;
        this.isValid.emit(false);
    }

    ngOnInit(): void {
    }

    public async searchForText(searchValue: string) {
        if (searchValue.length < 3) {
            this.searchResults = [];
        } else {
            const searchResults = await this.authenticationService.searchUsersAsync(searchValue, this.tournamentId);

            const results: SelectUserSearchResult[] = searchResults.map(result => ({
                ...result,
                ResultType: result.HasCurrentRegistration ? ResultType.ExistingRegistration : ResultType.ExistingUser
            }));

            if (!results.length && emailAddressRegex.test(searchValue)) {
                results[0] = {
                    UserId: ResultType.CreateUser,
                    UserName: searchValue,
                    StartPage: '',
                    ResultType: ResultType.CreateUser,
                    HasCurrentRegistration: false
                };
            }

            this.zone.run(() => {
                this.searchResults = results;
            });
        }
    }

    public async selectUserAsync(userId: GuidString, email: string) {
        if (userId === ResultType.CreateUser) {
            userId = await this.authenticationService.createNewUserAsync(email);
        }

        this.value = await this.registrationService.getRegistrationAsync(this.tournamentId, userId, true);

        this.router.navigateByUrl('/admin/register/' + userId + '/' + this.tournamentId + '/boat');
    }
}
