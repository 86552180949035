import {OnDestroy, Component} from '@angular/core';
import {Subject, Observable}  from 'rxjs';
import {take, takeUntil}      from 'rxjs/operators';

@Component({
    template: ''
})
// tslint:disable-next-line:component-class-suffix
export abstract class ComponentBase implements OnDestroy {
    private destroy$ = new Subject<void>();

    public takeUntilDestroyed<T>(obj: Observable<T>) {
        return obj.pipe(takeUntil(this.destroy$));
    }

    public takeSnapshotAsync<T>(obj: Observable<T>) {
        return this.takeUntilDestroyed(obj)
            .pipe(take(1))
            .toPromise();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
