
<div class="header">
    <div class="boat-registration-title">&nbsp;</div>

    <gx-icon-button
      [icon]="fontSolid.faSave"
      text="Save"
      (buttonClick)="save.emit()"
    ></gx-icon-button>

    <div class="spacer"></div>

    <gx-icon-button
      [icon]="fontSolid.faTimes"
      text="Cancel"
      color="var(--color-accent-alert)"
      (buttonClick)="cancel.emit()"
    ></gx-icon-button>
    <gx-icon-button
      [icon]="fontSolid.faSignOutAlt"
      text="Exit"
      (buttonClick)="saveExit.emit()"
    ></gx-icon-button>
  </div>
