import { Injectable } from '@angular/core';
import { PopupMessageService } from './popup-message.service';

type message = { message: string, title: string };

@Injectable({
    providedIn: 'root'
})
export class HttpErrorHandlerService {
    constructor(private popupMessageService: PopupMessageService) { }

    public async popWarningOnExceptionAsync<T>(promise: Promise<T>, throwOnError?: boolean): Promise<T> {
        try {
            console.log('web request beginning', { throwOnError });
            return await promise;
        }
        catch (ex) {
            const warning = this.getMessageFromResponse(ex);

            console.log('exception from web request', { warning, throwOnError });

            this.popupMessageService.showWarning(warning);
            if (throwOnError) {
                throw ex;
            }
            return null as any as T;
        }
    }

    private getMessageFromResponse(ex: any): message {
        if (!ex) {
            return {
                title: 'Error',
                message: 'An error occurred.'
            };
        }

        const error = ex?.error ?? ex;

        if (error.ExceptionType === 'System.ComponentModel.DataAnnotations.ValidationException') {
            return {
                title: 'Validation Exception',
                message: error.ExceptionMessage
            };
        }

        const message = error.ExceptionMessage ||
            error.Message ||
            JSON.stringify(error);

        return {
            title: 'Error',
            message: 'An error occurred. \n\n' + message
        }
    }
}
