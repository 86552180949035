import {LoginComponent}                   from './login/login.component';
import {OwnerHomeComponent}               from './owner-home/owner-home.component';
import {
    TournamentRegistrationComponent,
    TournamentFooterComponent,
    TournamentHeaderComponent,
    AnglersComponent,
    AnglerCopyComponent,
    BoatDetailsComponent,
    CalcuttasComponent,
    CheckoutComponent,
    StepHeaderComponent,
    EligibilityComponent
}                                         from './registration';
import {AdministrationComponent}          from './administration/administration/administration.component';
import {AdministrationHeaderComponent}    from './administration/administration-header/administration-header.component';
import {AnglerComponent}                  from './registration/anglers/angler-line-item/angler/angler.component';
import {AnglerEditComponent}              from './registration/anglers/angler-edit/angler-edit.component';
import {AnglerHeaderComponent}            from './registration/anglers/angler-line-item/angler-header/angler-header.component';
import {CheckoutOrderComponent}           from './registration/checkout/checkout-order/checkout-order.component';
import {EditCalcuttaEligibilityComponent} from './administration/edit-calcutta-eligibility/edit-calcutta-eligibility.component';
import {EditEligibilityComponent}         from './administration/edit-eligibility/edit-eligibility.component';
import {EligibilityBlockComponent}        from './registration/eligibility/eligibility-block/eligibility-block.component';
import {FileManagerComponent}             from './administration/file-manager/file-manager.component';
import {LoadingComponent}                 from './loading/loading.component';
import {SelectUserComponent}              from './registration/select-user/select-user/select-user.component';
import {EditTournamentWaiverComponent}    from './administration/edit-tournament-waiver/edit-tournament-waiver.component';
import {EditTournamentComponent}          from './administration/edit-tournament/edit-tournament.component';

export const tournamentComponents = [
    AdministrationComponent,
    AdministrationHeaderComponent,
    AnglerComponent,
    AnglerCopyComponent,
    AnglerEditComponent,
    AnglerHeaderComponent,
    AnglersComponent,
    BoatDetailsComponent,
    CalcuttasComponent,
    CheckoutComponent,
    CheckoutOrderComponent,
    EditCalcuttaEligibilityComponent,
    EditEligibilityComponent,
    EditTournamentComponent,
    EditTournamentWaiverComponent,
    EligibilityBlockComponent,
    EligibilityComponent,
    FileManagerComponent,
    LoadingComponent,
    LoginComponent,
    OwnerHomeComponent,
    SelectUserComponent,
    StepHeaderComponent,
    TournamentFooterComponent,
    TournamentHeaderComponent,
    TournamentRegistrationComponent
];

export {
    AdministrationComponent,
    AdministrationHeaderComponent,
    AnglerComponent,
    AnglerCopyComponent,
    AnglerEditComponent,
    AnglerHeaderComponent,
    AnglersComponent,
    BoatDetailsComponent,
    CalcuttasComponent,
    CheckoutComponent,
    CheckoutOrderComponent,
    EditCalcuttaEligibilityComponent,
    EditEligibilityComponent,
    EditTournamentComponent,
    EditTournamentWaiverComponent,
    EligibilityBlockComponent,
    EligibilityComponent,
    FileManagerComponent,
    LoadingComponent,
    LoginComponent,
    OwnerHomeComponent,
    SelectUserComponent,
    StepHeaderComponent,
    TournamentFooterComponent,
    TournamentHeaderComponent,
    TournamentRegistrationComponent
};

