<bw-step-header
        stepName="Select User"
        [tournamentName]="tournamentName"
>
</bw-step-header>

<b>User Email:</b><br/>
<input #searchText class="searchText"
       placeholder="User Email"
       type="text" (keyup)="searchForText(searchText.value)"/>

<div *ngFor="let user of searchResults" class="searchResultUser">
    {{ user.UserName }}
    <button [class]="user.ResultType" (click)="selectUserAsync(user.UserId, user.UserName)">
        <span *ngIf="user.ResultType === resultType.CreateUser">Create and Register</span>
        <span *ngIf="user.ResultType === resultType.ExistingUser">Select</span>
        <span *ngIf="user.ResultType === resultType.ExistingRegistration">Edit Registration</span>
    </button>

</div>
