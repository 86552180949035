<header>
    <bw-tournament-header
            (cancel)="cancel()"
            (save)="saveAsync()"
            (saveExit)="saveLogoutAsync()"
    ></bw-tournament-header>
</header>
<main [ngSwitch]="step" *ngIf="registration && tournament">
    <bw-select-user
            *ngSwitchCase="tournamentRoute.SelectUser"
            [tournamentName]="tournament.Description"
            [tournamentId]="tournament.TournamentId"
            [(value)]="registration"
            (isValid)="setStepValidity($event)"
            (valueChange)="updateValue($event)"
    ></bw-select-user>
    <bw-boat-details
            *ngSwitchCase="tournamentRoute.Boat"
            [tournament]="tournament"
            [tournamentName]="tournament.Description"
            [userId]="registrationUserId"
            [(value)]="registration"
            (valueChange)="updateValue($event)"
            (isValid)="setStepValidity($event)"></bw-boat-details>
    <bw-eligibility
            *ngSwitchCase="tournamentRoute.Eligibility"
            [tournamentName]="tournament.Description"
            [(value)]="registration"
            (valueChange)="updateValue($event)"
            (isValid)="setStepValidity($event)"></bw-eligibility>
    <bw-anglers
            *ngSwitchCase="tournamentRoute.Anglers"
            [tournamentName]="tournament.Description"
            [tournament]="tournament"
            [(value)]="registration"
            (valueChange)="updateValue($event)"
            (isValid)="setStepValidity($event)"></bw-anglers>
    <bw-calcuttas
            *ngSwitchCase="tournamentRoute.Calcuttas"
            [tournamentName]="tournament.Description"
            [(value)]="registration"
            [tournament]="tournament"
            (valueChange)="updateValue($event)"
            (isValid)="setStepValidity($event)"></bw-calcuttas>
    <bw-checkout
            *ngSwitchCase="tournamentRoute.Checkout"
            [tournamentName]="tournament.Description"
            [(value)]="registration"
            [tournament]="tournament"
            (valueChange)="updateValue($event)"
            (isValid)="setStepValidity($event)"></bw-checkout>
    <div *ngSwitchDefault>Registration Page {{ step }} Not Found</div>
</main>
<footer>
    <bw-tournament-footer *ngIf="registration"
                          [previousStep]="previousStep"
                          [nextStep]="nextStep"
                          [enableNext]="currentStepValid | async"
                          (helpClick)="showHelp()"
                          (closeClick)="saveExitAsync()"
                          (previousClick)="goPreviousStep()"
                          (nextClick)="goNextStep()"
    ></bw-tournament-footer>
</footer>
