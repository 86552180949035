import { Injectable } from '@angular/core';
import { ApiQueryService } from './api/api-query.service';
import { Dictionary, GuidString, Integer, YesNo } from '@gx/core';
import {
    CreditCardPayment,
    ManualPaymentUpdateModel,
    TeamAnglerViewModel,
    TeamListItemViewModel,
    TeamUpdateModel,
    TeamViewModel
} from '@bluewater/viewmodels';
import { ApiCommandService } from './api/api-command.service';

function throwValidationError(message: string) {
    // @ts-ignore: Typescript doesn't understand the specified Error construtor
    throw new Error(message, { cause: "LocalValidation"});
}

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {
    private teams: Dictionary<string, TeamViewModel> = {};
    private teamLists: Dictionary<string, TeamListItemViewModel[]> = {};

    constructor(private apiQueryService: ApiQueryService,
        private apiCommandService: ApiCommandService) { }

    public async getTeamListAsync(userId: GuidString,
        forceRefresh = false) {
        let teamList = this.teamLists[userId];

        if (forceRefresh || !teamList) {
            teamList = await this.apiQueryService.getTeamListAsync(userId);
            this.teamLists[userId] = teamList;
        }

        return teamList;
    }

    public async getRegistrationAsync(
        tournamentId: Integer,
        userId: GuidString,
        forceRefresh = false
    ): Promise<TeamViewModel> {
        const teamKey = tournamentId + ':' + userId;
        let team = this.teams[teamKey];

        if (forceRefresh || !team) {
            team = await this.apiQueryService.getTeamAsync(tournamentId, userId);
            this.teams[teamKey] = team;
        }

        return team;
    }

    public getCopyableAnglersAsync(
        currentTournamentId: Integer,
        userId: GuidString
    ): Promise<TeamAnglerViewModel[]> {
        return this.apiQueryService.getCopyableAnglers(currentTournamentId, userId);
    }

    public async saveAsync(registration: TeamUpdateModel) {
        await this.apiCommandService.saveTeamAsync(registration);
    }

    public async updateTeamOrder(teamId: Integer) {
        await this.apiCommandService.updateTeamOrder(teamId);
    }

    public getTeamOrdersAsync(currentTournamentId: Integer, userId: GuidString) {
        return this.apiQueryService.getTeamOrdersAsync(currentTournamentId, userId);
    }

    public applyCreditCardPayment(creditCardPayment: CreditCardPayment, throwOnException: boolean) {
        if (creditCardPayment.AgreeToPayConvenienceFee !== YesNo.Yes) {
            throwValidationError("You must select Yes to accept the convenience fee to continue processing your online credit card payment.");
        }

        return this.apiCommandService.applyCreditCardPayment(creditCardPayment, throwOnException);
    }

    public async applyManualPayment(manualPaymentInfo: ManualPaymentUpdateModel, throwOnException: boolean): Promise<void> {
        if (!manualPaymentInfo.PaymentTypeId) {
            throwValidationError('Payment type must be specified');
        }

        if (!manualPaymentInfo.Amount) {
            throwValidationError('Amount must be specified.');
        }

        if (!manualPaymentInfo.OrderId) {
            throwValidationError('OrderId must be specified.');
        }

        if (!manualPaymentInfo.TeamId) {
            throwValidationError('TeamId must be specified.');
        }

        await this.apiCommandService.applyManualPayment(manualPaymentInfo, throwOnException);
    }

    public ignorePreviousAngler(teamId: number, anglerId: number) {
        return this.apiCommandService.ignorePreviousAngler(teamId, anglerId);
    }

    public administrativeSave(teamId: number, isComplete?: boolean, boatNumber?: number) {
        const values = { isComplete, boatNumber };
        return this.apiCommandService.administrativeSave(teamId, values);
    }

    public getSecurityHints() {
        return this.apiQueryService.getSecurityHints();
    }
}
