import {Component, Input} from '@angular/core';

@Component({
    selector: 'bw-angler-header',
    templateUrl: './angler-header.component.html',
    styleUrls: [
        '../angler-item.component.scss',
        './angler-header.component.scss'
    ]
})
export class AnglerHeaderComponent {
    @Input() anglerWaiver = '';
    @Input() anglerWaiverValid = false;
}
