<div class="eligibility">
    <gx-multi-input-yes-no [(value)]="value" (valueChange)="updateValue()">
    </gx-multi-input-yes-no>
    <div class="name">
        {{ name }}
    </div>
    <div class="description">
        <div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
