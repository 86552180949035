<bw-administration-header>
    File Manager {{ folder ? ' - ' + folder : '' }}
</bw-administration-header>

<main>
    <div *ngIf="!folder">
        <h2>Folders</h2>
        <div *ngFor="let folder of folders">
            <a [routerLink]="folder.name">{{ folder.name }}</a>
        </div>

        <input #folderName class="folder-name" type="text" (change)="setNewValue($event)"/>
        <button [disabled]="!newItem" (click)="createNewFolder(this.newItem)">Create New Folder</button>
    </div>

    <div *ngIf="folder">
        <h2>Files</h2>
        <div [innerHTML]="folderMessage">

        </div>
        <div class="file-list" *ngFor="let file of files">
            <a [href]="file.url" target="_blank"> {{ file.name }}</a>
            <a (click)="deleteFile(file.name)">Delete</a>
        </div>

        <br/>
        <br/>
        <input type="file" (change)="handleFiles($event)" multiple #inputFile>
        <br/>
        <br/>
        <div class="selected-file" *ngFor="let selectedFile of selectedFiles">
            <fa-icon *ngIf="selectedFile" [icon]="fontAwesome.faFile"></fa-icon>
            <div>{{ selectedFile.file.name }}</div>
            <div>{{ selectedFile.status}}</div>
        </div>
        <br/>
        <button [disabled]="!selectedFiles.length" (click)="uploadFilesAsync()">Upload
            File{{ selectedFiles.length > 1 ? 's' : '' }}</button>
    </div>

</main>
<footer>
    <gx-icon-button
            [icon]="fontSolid.faDoorClosed"
              text="Close"
            (buttonClick)="close()"
    ></gx-icon-button>
</footer>
