<input [type]="type"
       [attr.pattern]="pattern"
       [required]="required"
       [placeholder]="required ? 'Required' : ''"
       [autocomplete]="autocomplete"
       [(ngModel)]="value"
       (blur)="updateValue()"
/>
<span class="tooltip" *ngIf="tooltip" [innerHTML]="tooltip">
</span>

