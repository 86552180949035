import { TeamEligibilityViewModel } from '@bluewater/viewmodels';
import { YesNo } from '@gx/core';

export function sleepAsync(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function toYesNo(idSet: TeamEligibilityViewModel[], eligibilityId: number): YesNo | undefined {
    if (!idSet) {
        return undefined;
    }

    const record = idSet.find(v => v.EligibilityId === eligibilityId);
    return record && record.Answer || undefined;
}

export function isObject(value: any) {
    return (value && typeof value === 'object' && !Array.isArray(value));
}

export function getErrorString(error: any): string {
    if (!error) {
        return "Unknown Error";
    }

    return error.message || error.toString();
}

export function truncDecimalPlaces(value: number, places: number = 2) {
    const multiplier = Math.pow(10, places);
    return Math.trunc(value * multiplier) / multiplier;
}