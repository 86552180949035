import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bw-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss'],
})
export class StepHeaderComponent implements OnInit {
  @Input() stepName = '';
  @Input() stepNumber = 0;
  @Input() totalStepCount = 0;
  @Input() tournamentName = '';

  ngOnInit(): void {}
}
