import {ChangeDetectorRef, Component, Input, NgZone, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValuedComponentBase}                                                           from '../../../core/component-base/valued-component-base';
import {TeamAnglerViewModel, TeamViewModel, TournamentViewModel}                       from '@bluewater/viewmodels';
import {LookupService, RegistrationService}                                            from '@bluewater/services';
import {CategorizedLookup}                                            from '@gx/core';
import {anglerCrewMax, anglerReleaseInvalidId, countCrewMembers, createNewAngler, validateTeamAnglers} from './extensions/functions';
import {ValidationMessage}                                            from './extensions/validation.message';

@Component({
    selector: 'bw-anglers',
    templateUrl: './anglers.component.html',
    styleUrls: ['./anglers.component.scss']
})
export class AnglersComponent extends ValuedComponentBase<TeamViewModel> implements OnInit, OnChanges {
    @Input() public tournamentName = '';
    @Input() public tournament!: TournamentViewModel;

    public previousAnglers: TeamAnglerViewModel[] = [];

    public editAnglerMode = false;
    public currentAngler?: TeamAnglerViewModel;
    public lookups: CategorizedLookup[] = [];
    public validationMessages: ValidationMessage[] = [];
    public anglerWaiverValid = false;

    public get hasAnglers(): boolean {
        if (!this.value) {
            return false;
        }

        if (!this.value!.Anglers) {
            return false;
        }

        const crewCount = countCrewMembers(this.value!);
        return crewCount > 0;
    }

    public get maxCrewMembers(): number {
        return anglerCrewMax;
    }

    constructor(
        private registrationService: RegistrationService,
        private lookupService: LookupService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone) {

        super();

        this.validate = team => this.checkIsValid(team);
    }

    ngOnInit(): void {
        this.onInitAsync();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const value = this.value!;

            if (!value.Anglers) {
                value.Anglers = [];
            }
        }

        if (changes.editAnglerMode) {
            if (changes.editAnglerMode.currentValue) {
                // cannot proceed to next screen if in angler edit mode
                this.isValid.emit(false);
            } else {
                // upon leaving angler edit mode, update isValid
                this.updateIsValid();
            }
        }

        super.ngOnChanges(changes);
    }

    private async onInitAsync(): Promise<void> {
        const team = this.value!;

        const lookups = await this.lookupService.getLookups();
        const previousAnglers = await this.registrationService.getCopyableAnglersAsync(team.TournamentId, team.UserId);

        this.zone.run(() => {
            this.lookups = lookups;
            this.previousAnglers = previousAnglers;
        });
    }

    private emitIsValid(isValid: boolean) {
        this.isValid.emit(isValid);
    }

    public updateIsValid() {
        this.emitIsValid(this.validate!(this.value));
    }

    public addNewAngler() {
        const newAngler = createNewAngler();
        this.addAngler(newAngler);
        this.currentAngler = newAngler;
        this.editAnglerMode = true;
    }

    public addAngler(angler: TeamAnglerViewModel): void {
        const anglers = [...this.value!.Anglers];
        if (angler.IsCaptain) {
            anglers.forEach(a => a.IsCaptain = false);
        }

        if (angler.IsOwner) {
            anglers.forEach(a => a.IsOwner = false);
        }

        anglers.push(angler);
        this.value!.Anglers = anglers;
        this.updateIsValid();
    }

    public async deleteAngler(angler: TeamAnglerViewModel): Promise<void> {
        let title = 'angler';
        if (angler.IsCaptain && angler.IsOwner) {
            title = 'captain-owner';
        } else if (angler.IsCaptain) {
            title = 'captain';
        } else if (angler.IsOwner) {
            title = 'owner';
        }
        if (confirm('Delete ' + title + ' ' + angler.FirstName + ' ' + angler.LastName)) {
            this.zone.run(() => {
                const anglers = this.value!.Anglers.filter(a => a !== angler);
                this.value!.Anglers = anglers;
                this.updateIsValid();
            });

            await this.registrationService.saveAsync(this.value!);
        }
    }

    private checkIsValid(team: TeamViewModel | null | undefined) {
        if (!team) {
            return false;
        }

        // not valid if any angler is being edited
        if (this.editAnglerMode) {
            return false;
        }

        const validationMessages: ValidationMessage[] = [];
        validateTeamAnglers(team, validationMessages);
        this.validationMessages = validationMessages;
        this.anglerWaiverValid = !validationMessages.some(vm => vm.id === anglerReleaseInvalidId);

        
        return !validationMessages.length;
    }

    public exitEditMode() {
        this.editAnglerMode = false;
        this.currentAngler = undefined;
        this.updateIsValid();
    }
}
