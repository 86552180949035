import {Pipe, PipeTransform} from '@angular/core';
import {SysDate}             from '@bluewater/viewmodels';
import {dateIsPast}          from '../../components/registration/anglers/extensions/date-functions';

const months = [
    'NULL',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

@Pipe({
    name: 'gxIsPast'
})
export class GxDatePastPipe implements PipeTransform {

    transform(value: SysDate): boolean {
        if (!value || !value.Month || !value.Day || !value.Year) {
            return false;
        }

        return dateIsPast(value);
    }
}
