import {Component, OnInit}      from '@angular/core';
import {fontSolid, fontRegular} from '../../../modules';
import {Router}                 from '@angular/router';
import {AuthenticationService}  from '@bluewater/services';

@Component({
    selector: 'bw-administration-header',
    templateUrl: './administration-header.component.html',
    styleUrls: ['./administration-header.component.scss']
})
export class AdministrationHeaderComponent implements OnInit {
    public fontSolid = fontSolid;
    public fontRegular = fontRegular;

    constructor(private router: Router,
                private authenticationService: AuthenticationService) { }

    ngOnInit(): void {
    }

    public async logoutAsync() {
        await this.authenticationService.logoutUserAsync();
        this.router.navigateByUrl('/');
    }
}
