import {Component, NgZone, OnInit}          from '@angular/core';
import {ActivatedRoute, Router}             from '@angular/router';
import {BehaviorSubject}                    from 'rxjs';
import {ComponentBase, GuidString, Integer} from 'src/app/core';

import {TeamViewModel, TournamentViewModel}                                                 from '@bluewater/viewmodels';
import {TournamentRoute, TournamentRoutes}                                                  from '../models';
import {AuthenticationService, RegistrationService, TournamentService, PopupMessageService} from '../../../services';
import {UsageContext}                                                                       from '../../../core/constants/usage-context';
import {switchToLegacyApp}                                                                  from '../../../modules';

const emptyTeamViewModel: TeamViewModel = {
    BoatLength: 0,
    BoatMake: '',
    BoatName: '',
    BoatNumber: 0,
    TakeAHero: undefined,
    Calcuttas: [],
    ElectronicsMake: 0,
    Eligibilities: [],
    EligibilitySignature: '',
    EngineMake: '',
    EnginePower: 0,
    EngineSize: '',
    EntryComplete: false,
    HasPreviousBoats: false,
    NumOfEngines: 0,
    TeamId: 0,
    TournamentId: 0,
    UserId: '',
    Anglers: [],
    FirstTimerTeam: undefined,
    PreviousTeamId: undefined,
    PlannedPaymentTypeId: undefined
};

@Component({
    selector: 'bw-tournament-registration',
    templateUrl: './tournament-registration.component.html',
    styleUrls: ['./tournament-registration.component.scss']
})
export class TournamentRegistrationComponent extends ComponentBase
    implements OnInit {
    public tournamentId?: Integer;
    public tournamentRoute = TournamentRoute;
    public step: TournamentRoute = TournamentRoute.Unknown;
    public previousStep?: TournamentRoute;
    public nextStep?: TournamentRoute;
    public currentStepValid = new BehaviorSubject<boolean>(false);
    public registration: TeamViewModel | null | undefined;
    public tournament: TournamentViewModel | null | undefined;
    public usageContext = UsageContext.usageContextUser;
    public registrationUserId?: GuidString;

    private lastSavedRegistration = '';

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private zone: NgZone,
                private popupMessageService: PopupMessageService,
                private currentUserService: AuthenticationService,
                private tournamentService: TournamentService,
                private registrationService: RegistrationService) {
        super();

        this.takeUntilDestroyed(this.activatedRoute.data).subscribe(data => {
            this.usageContext = data.usageContext;
        });

        this.takeUntilDestroyed(this.activatedRoute.params).subscribe((params) => {
            const paramUserId = params.userId;
            this.tournamentId = params.tournamentId;
            this.updateStep(params.step);

            this.getRegistration(paramUserId, params.tournamentId);
        });
    }

    ngOnInit(): void {

    }

    public async getRegistration(userId: string, tournamentId: Integer, forceRefresh?: boolean) {
        const registrationUserId =
            userId ||
            (await this.currentUserService.getCurrentUserAsync())?.UserId!;

        this.registrationUserId = registrationUserId;

        const tournament = await this.tournamentService.getTournamentAsync(tournamentId);
        const registration = registrationUserId === 'unknown' ?
            {
                ...emptyTeamViewModel,
                TournamentId: tournamentId
            } :
            await this.registrationService.getRegistrationAsync(
                tournamentId,
                registrationUserId,
                forceRefresh
            );

        this.zone.run(() => {
            this.tournament = tournament;
            this.registration = registration;
            this.lastSavedRegistration = JSON.stringify(registration);
        });
    }

    private updateStep(step: TournamentRoute) {
        const thisIndex = TournamentRoutes
            .findIndex((tr) => tr === step);

        this.step = step;
        this.previousStep = TournamentRoutes[thisIndex - 1];
        this.nextStep = TournamentRoutes[thisIndex + 1];
    }

    public updateValue(registration: TeamViewModel) {
        this.registration = registration;
    }

    public showHelp() {
        window.open('https://www.bluewatermovements.com/faq/', '_blank');
    }

    public async goNextStep() {
        await this.saveAsync();
        if (this.nextStep) {
            this.navigate(this.nextStep);
        } else {
            this.navigateHome();
        }
    }

    public async goPreviousStep() {
        await this.registrationService.saveAsync(this.registration!);
        this.navigate(this.previousStep);
    }

    public async saveExitAsync() {
        await this.saveAsync();
        this.navigateHome();
    }

    public async saveLogoutAsync() {
        await this.saveAsync();
        await this.logoutAsync();
    }

    public async saveAsync() {
        await this.registrationService.saveAsync(this.registration!);

        const {TournamentId, UserId} = this.registration!;

        if (!this.registration!.TeamId) {
            const registration = await this.registrationService.getRegistrationAsync(
                TournamentId,
                UserId,
                true
            );

            this.zone.run(() => {
                this.registration = registration;
            });
        }

        this.lastSavedRegistration = JSON.stringify(this.registration);
    }

    public cancel() {
        const isDirty = this.lastSavedRegistration !== JSON.stringify(this.registration);

        if (!isDirty) {
            this.navigateHome();
            return;
        }

        if (confirm('Are you sure you wish to cancel?  Current unsaved changes will be lost. ')) {
            this.navigateHome();
        }
    }

    private navigate(step?: TournamentRoute) {
        if (step) {
            this.router.navigate(['../', step], {relativeTo: this.activatedRoute});
        }
    }

    public debugNavigate(route: string) {
        this.router.navigate(['../', route], {relativeTo: this.activatedRoute});
    }

    private async logoutAsync() {
        await this.currentUserService.logoutUserAsync();
        this.router.navigateByUrl('/');
    }

    private navigateHome() {
        if (this.usageContext === UsageContext.usageContextUser) {
            const dt = new Date();

            this.router.navigateByUrl('/home?dateNow=' + dt.getTime());
        } else {
            switchToLegacyApp();
        }
    }

    public setStepValidity(isValid: boolean) {
        this.currentStepValid.next(isValid);
    }
}
