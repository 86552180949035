import {Component, NgZone, OnInit}                        from '@angular/core';
import {LookupService, TournamentService}                 from '@bluewater/services';
import {ActivatedRoute}                                   from '@angular/router';
import {distinct}                                         from 'rxjs/operators';
import {ComponentBase, Integer}                           from '@gx/core';
import {TournamentCalcuttaViewModel, TournamentViewModel} from '@bluewater/viewmodels';
import {sleepAsync}                                       from '../../../core/functions';
import {fontRegular, fontSolid, switchToLegacyApp}        from '../../../modules';

enum CalcuttaChangeStatus {
    Unchanged = 'Unchanged',
    Saving = 'Saving...',
    Saved = 'Saved'
}

type CalcuttaChangeModel = TournamentCalcuttaViewModel & { updateStatus?: CalcuttaChangeStatus };

@Component({
    selector: 'bw-edit-calcutta-eligibility',
    templateUrl: './edit-calcutta-eligibility.component.html',
    styleUrls: ['./edit-calcutta-eligibility.component.scss']
})
export class EditCalcuttaEligibilityComponent extends ComponentBase implements OnInit {
    public tournamentId = 0;
    public tournament?: TournamentViewModel;
    public tournamentCalcuttas: CalcuttaChangeModel[] = [];
    public calcuttaChangeStatus = CalcuttaChangeStatus;

    public fontSolid = fontSolid;
    public fontRegular = fontRegular;

    constructor(private tournamentService: TournamentService,
                private lookupService: LookupService,
                private route: ActivatedRoute,
                private zone: NgZone) {
        super();

        this.takeUntilDestroyed(this.route.params)
            .pipe(distinct())
            .subscribe(params => {
                this.tournamentId = +params.tournamentId;
                this.fetchTournamentAsync(this.tournamentId);
            });
    }

    ngOnInit(): void {
    }

    private async fetchTournamentAsync(tournamentId: Integer) {
        const tournament = await this.tournamentService.getTournamentAsync(tournamentId, true);

        if (!tournament) {
            throw new Error('Unable to load tournament ' + tournamentId);
        }



        this.zone.run(() => {
            this.tournament = tournament;
            this.tournamentCalcuttas = tournament.Calcuttas;
        });
    }

    public async updateRequiredEligibility(calcuttaProductId: number, event: Event) {
        const selectedOption = (event.target as HTMLSelectElement).selectedOptions[0];
        const selectedValue = Number(selectedOption.value);

        this.tournamentCalcuttas.forEach(tc => tc.updateStatus = CalcuttaChangeStatus.Unchanged);

        const calcuttaRecord = this.tournamentCalcuttas.find(tc => tc.CalcuttaProductId === calcuttaProductId)!;
        const eligibilityId = this.tournament?.Eligibilities.find(el => el.EligibilityId === selectedValue)?.EligibilityId;

        calcuttaRecord.updateStatus = CalcuttaChangeStatus.Saving;

        await this.tournamentService.saveTournamentCalcuttaEligibilityAsync(
            this.tournamentId,
            calcuttaProductId,
            eligibilityId
        );

        this.zone.run(() => calcuttaRecord.updateStatus = CalcuttaChangeStatus.Saved);

        await sleepAsync(5000);

        this.zone.run(() => calcuttaRecord.updateStatus = CalcuttaChangeStatus.Unchanged);
    }

    public close() {
        switchToLegacyApp();
    }
}
