import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ValuedComponentBase } from '../../../core/component-base/valued-component-base';
import {
    CreditCardPayment,
    ManualPaymentUpdateModel,
    OrderViewModel,
    SecurityHintInformation, TeamCalcuttaViewModel,
    TeamViewModel, TournamentCalcuttaViewModel,
    TournamentViewModel
} from '@bluewater/viewmodels';
import { ActivatedRoute } from '@angular/router';
import { UsageContext } from '../../../core/constants/usage-context';
import { LookupService, RegistrationService } from '@bluewater/services';
import { CategorizedLookup, GuidString, Integer, YesNo } from '@gx/core';
import { SpecialPermissions } from '../../../core/constants/special-permissions';
import { SelectedEligibilityViewModel } from '../../../view-models/SelectedEligibilityViewModel';
import { toYesNo } from '../../../core/functions';

type SelectedOrder = OrderViewModel & { PaymentBalance: number; ItemBalance: number; PlannedPaymentTypeId: number };
type CalcuttaViewModel = {
    tournamentCalcutta: TournamentCalcuttaViewModel,
    teamCalcutta: TeamCalcuttaViewModel
};

function getCalcuttaViewModels(teamCalcuttas: TeamCalcuttaViewModel[], tournamentCalcuttas: TournamentCalcuttaViewModel[]) {
    return teamCalcuttas.map<CalcuttaViewModel>(teamCalcutta => ({
        teamCalcutta,
        tournamentCalcutta: tournamentCalcuttas.find(tc => tc.CalcuttaProductId === teamCalcutta.CalcuttaProductId)!
    }));
}

@Component({
    selector: 'bw-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent extends ValuedComponentBase<TeamViewModel> implements OnInit {
    @Input() tournamentName = '';
    @Input() tournament?: TournamentViewModel;

    public lookups: CategorizedLookup[] = [];
    public paymentCategories: CategorizedLookup[] = [];
    public usageContext = UsageContext.usageContextUser;
    public isStaffContext = false;
    public userIsAdmin = false;

    public loaded = false;
    public ordersLoaded = false;
    public orders: OrderViewModel[] = [];
    public orderBalanceTotal = 0;
    public selectedOrder!: SelectedOrder;
    public showCreditCardPaymentScreen = false;
    public showAdminPaymentScreen = false;
    public showAdminPaymentButton = true;

    public creditCardInfo: CreditCardPayment = this.createEmptyCreditCardPayment();
    public manualPaymentInfo: ManualPaymentUpdateModel = this.createEmptyManualPaymentInfo();
    public newBoatNumber?: number;
    public securityHints?: SecurityHintInformation;
    public boatNumberChanging = false;
    public calcuttaInfo: CalcuttaViewModel[] = [];
    public eligibilities: SelectedEligibilityViewModel[] = [];
    public YesNo = YesNo;

    public paymentMethodSelected = false;
    public paymentMethodIncludesConvenienceFee = false;

    constructor(private activatedRoute: ActivatedRoute,
        private lookupService: LookupService,
        private registrationService: RegistrationService,
        private zone: NgZone) {
        super();

        this.takeUntilDestroyed(this.activatedRoute.data).subscribe(data => {
            this.usageContext = data.usageContext;
            this.isStaffContext = (this.usageContext === UsageContext.usageContextStaff);
        });

        const self = this;
        const fillcc = () => {
            self.zone.run(() => {
                self.creditCardInfo = self.createDebugCreditCardPayment();
            });
        };

        // @ts-ignore
        window.fillcc = fillcc;

        if (window.parent) {
            // @ts-ignore
            window.parent.fillcc = fillcc;
        }

        this.validate = team => this.validatePaymentComplete(team);
    }

    private validatePaymentComplete(team?: TeamViewModel): boolean {
        return this.orderBalanceTotal === 0;
    }

    ngOnInit(): void {
        this.onInitAsync();
    }

    private async onInitAsync(): Promise<void> {
        const lookups = await this.lookupService.getLookups();

        const teamId = this.value!.TeamId;
        await this.registrationService.updateTeamOrder(teamId);

        const { UserId, TournamentId } = this.value!;
        const { orders, orderBalance } = await this.getUpdatedOrders(TournamentId, UserId);

        const securityHints = await this.registrationService.getSecurityHints();
        const userIsAdmin = securityHints.UserPermissions.some(uperm => uperm === SpecialPermissions.BluewaterAdministration);

        const calcuttaInfo = getCalcuttaViewModels(
            this.value!.Calcuttas,
            this.tournament!.Calcuttas
        );

        const teamEligibilities = this.value!.Eligibilities;
        const eligibilities: SelectedEligibilityViewModel[] = this.tournament!
            .Eligibilities
            .map(el => ({
                EligibilityId: el.EligibilityId,
                Description: el.Description,
                Name: el.Name,
                Answer: toYesNo(teamEligibilities, el.EligibilityId)
            }));

        this.zone.run(() => {
            this.securityHints = securityHints;
            this.userIsAdmin = userIsAdmin;
            this.lookups = lookups;

            this.orders = orders;
            this.orderBalanceTotal = orderBalance;
            this.calcuttaInfo = calcuttaInfo;
            this.eligibilities = eligibilities;
            this.loaded = true;
            this.ordersLoaded = true;

            this.updateValue();
        });
    }

    private createEmptyCreditCardPayment(): CreditCardPayment {
        return {
            Amount: 0,
            BillingAddress1: '',
            CVVCode: '',
            Cellular: '',
            City: '',
            CreditCardNumber: '',
            EMail: '',
            ExpirationDate: '',
            FirstNameOnCreditCard: '',
            LastNameOnCreditCard: '',
            OrderId: 0,
            State: '',
            Telephone: '',
            Zip: '',
            AgreeToPayConvenienceFee: YesNo.No,
            ConvenienceFeeAmount: 0
        };
    }

    private createDebugCreditCardPayment(): CreditCardPayment {
        return {
            Amount: 0,
            EMail: 'john.smith@example.com',
            BillingAddress1: '1 Billing Address',
            City: 'AnyCity',
            State: 'FL',
            Zip: '34777',
            CVVCode: '555',
            CreditCardNumber: '4007000000027',
            ExpirationDate: '0325',
            FirstNameOnCreditCard: 'John',
            LastNameOnCreditCard: 'Smith',
            OrderId: 0,
            Telephone: '402-555-1234',
            Cellular: '402-555-1235',
            AgreeToPayConvenienceFee: YesNo.No,
            ConvenienceFeeAmount: 0
        };
    }

    private createEmptyManualPaymentInfo(): ManualPaymentUpdateModel {
        return { Amount: 0, Comment: '', OrderId: 0, PaymentTypeId: 0, TeamId: 0 };
    }

    public async updateRegistrationAndOrders() {
        this.ordersLoaded = false;
        const { UserId, TournamentId } = this.value!;
        const { orders, orderBalance } = await this.getUpdatedOrders(TournamentId, UserId);
        const { registration, calcuttaInfo } = await this.getUpdatedRegistration();

        this.zone.run(() => {
            this.value = registration;
            this.orders = orders;
            this.calcuttaInfo = calcuttaInfo;
            this.orderBalanceTotal = orderBalance;
            this.updateValue();
            this.ordersLoaded = true;
        });
    }

    public applyPaymentPreset(amount: number, paymentTypeId: number): void {
        this.manualPaymentInfo.Amount = +amount.toFixed(2);

        // don't change payment type if already selected
        if (!this.manualPaymentInfo.PaymentTypeId) {
            this.manualPaymentInfo.PaymentTypeId = paymentTypeId;
        }
    }

    public async setEntryComplete(isComplete: boolean) {
        const teamId = this.value!.TeamId;
        await this.registrationService.administrativeSave(teamId, isComplete);

        // setting isComplete might set the boat number, so update the records from the server
        await this.updateRegistrationAndOrders();
    }

    public async updateBoatNumber(newBoatNumber?: number) {
        const teamId = this.value!.TeamId;
        await this.registrationService.administrativeSave(teamId, undefined, newBoatNumber);
        this.value!.BoatNumber = newBoatNumber;
    }

    public async getUpdatedRegistration() {
        const registration = await this.registrationService.getRegistrationAsync(
            this.value!.TournamentId,
            this.value!.UserId,
            true
        );

        const calcuttaInfo = getCalcuttaViewModels(
            registration.Calcuttas,
            this.tournament!.Calcuttas
        );

        return {
            registration,
            calcuttaInfo
        };
    }

    public async getUpdatedOrders(currentTournamentId: Integer, userId: GuidString) {
        const orders = await this.registrationService.getTeamOrdersAsync(currentTournamentId, userId);

        const orderBalance = orders
            .reduce((total, order) => total + order.Balance, 0);

        return {
            orders,
            orderBalance
        };
    }
} 