import { Injectable } from '@angular/core';
import { FeeViewModel } from '@bluewater/viewmodels';
import { CategorizedLookup } from "../core/models";
import { ApiQueryService } from './api/api-query.service';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  private _fees?: FeeViewModel[];

  private _lookups?: CategorizedLookup[];
  private _emptyLookup: CategorizedLookup = {
    CategoryKey: '',
    Label: '',
    Id: 0,
    Value: ''
  };

  constructor(private apiService: ApiQueryService) {
  }

  public EmptyLookup = this._emptyLookup;

  public async getLookups(): Promise<CategorizedLookup[]> {
    if (!this._lookups) {
      this._lookups = await this.apiService.getLookups();
    }

    return this._lookups;
  }

  public getEligibilityListAsync() {
    return this.apiService.getEligibilityList();
  }

  public async getFees() {
    if (!this._fees) {
      this._fees = await this.apiService.getFees();
    }

    return this._fees;
  }
}
