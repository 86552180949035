import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {Injectable}                      from '@angular/core';
import {DomSanitizer}                        from '@angular/platform-browser';
import {GxPopupYesNoComponent}               from '../core/components/gx-popup-yes-no/gx-popup-yes-no.component';

type MessageType = { message: string, title: string };

@Injectable({
    providedIn: 'root'
})
export class PopupMessageService {
    constructor(
        private toastr: ToastrService,
        private readonly domSanitizer: DomSanitizer) {}

    public showWarning(warning: MessageType): void {
        warning = warning || {};
        const message = warning.message ?? "An unexpected error has occurred.";
        const title = warning.title ?? "Unexpected Error";

        this.toastr.warning(message, title);
    }

    public showModal(title: string, message: string, callback: () => undefined | any) {
        const config: Partial<IndividualConfig> = {
            enableHtml: true,
            tapToDismiss: true,
            disableTimeOut: true,
            positionClass: 'toast-top-center',
            closeButton: true
        };

        const toast = this.toastr.warning(
            'generic message',
            title,
            config
        );

        const yesNoComponent = toast.portal.instance as GxPopupYesNoComponent;
        yesNoComponent.onClick.subscribe(value => {
            if (value === 'yes') {
                callback();
            }

            toast.portal.destroy();
            this.toastr.clear(toast.toastId);
        });
    }
}
