import {GxButtonComponent}         from './gx-button/gx-button.component';
import {GxIconButtonComponent}     from './gx-icon-button/gx-icon-button.component';
import {GxLabeledInputComponent}   from './gx-labeled-input/gx-labeled-input.component';
import {GxTextInputComponent}      from './gx-text-input/gx-text-input.component';
import {GxThemeBannerComponent}    from './gx-theme-banner/gx-theme-banner.component';
import {GxThemeCardComponent}      from './gx-theme-card/gx-theme-card.component';
import {GxBoolSliderComponent}     from './gx-bool-slider/gx-bool-slider.component';
import {GxMultiInputComponent}     from './gx-multi-input/gx-multi-input.component';
import {
    GxMultiInputComponentDropdown,
    GxMultiInputComponentRadio,
    GxMultiInputComponentYesNoComponent
}                                  from './gx-multi-input/gx-multi-input-variants.component';
import {GxTelephoneInputComponent} from './gx-text-input/gx-text-input-variants.component';

export {
    GxButtonComponent,
    GxIconButtonComponent,
    GxThemeBannerComponent,
    GxThemeCardComponent,
    GxLabeledInputComponent,
    GxTextInputComponent,
    GxTelephoneInputComponent,
    GxBoolSliderComponent,
    GxMultiInputComponent,
    GxMultiInputComponentDropdown,
    GxMultiInputComponentRadio,
    GxMultiInputComponentYesNoComponent
};
export const components = [
    GxButtonComponent,
    GxIconButtonComponent,
    GxThemeBannerComponent,
    GxThemeCardComponent,
    GxLabeledInputComponent,
    GxTextInputComponent,
    GxTelephoneInputComponent,
    GxBoolSliderComponent,
    GxMultiInputComponent,
    GxMultiInputComponentDropdown,
    GxMultiInputComponentRadio,
    GxMultiInputComponentYesNoComponent
];
