import {Component, Input}                       from '@angular/core';
import {GxMultiInputComponent, MultiInputStyle} from './gx-multi-input.component';
import {YesNo}                                  from '../../models/types';

@Component({
    selector: 'gx-multi-input-dropdown',
    templateUrl: './gx-multi-input.component.html',
    styleUrls: ['./gx-multi-input.component.scss']
})
export class GxMultiInputComponentDropdown extends GxMultiInputComponent {
    @Input() inputStyle = MultiInputStyle.Dropdown;

    constructor() {
        super();
    }
}

@Component({
    selector: 'gx-multi-input-radio',
    templateUrl: './gx-multi-input.component.html',
    styleUrls: ['./gx-multi-input.component.scss']
})
export class GxMultiInputComponentRadio extends GxMultiInputComponent {
    @Input() inputStyle = MultiInputStyle.Radio;

    constructor() {
        super();
    }

}

@Component({
    selector: 'gx-multi-input-yes-no',
    templateUrl: './gx-multi-input.component.html',
    styleUrls: ['./gx-multi-input.component.scss']
})
export class GxMultiInputComponentYesNoComponent extends GxMultiInputComponent {
    @Input() inputStyle = MultiInputStyle.YesNo;

    categoryKey = 'yes-no';

    constructor() {
        super();

        this.options = [{
            CategoryKey: this.categoryKey,
            Label: 'Yes',
            Id: YesNo.Yes,
            Value: 'Yes'
        }, {
            CategoryKey: this.categoryKey,
            Label: 'No',
            Id: YesNo.No,
            Value: 'No'
        }];

        this.compiledOptions = this.options;
    }

}
