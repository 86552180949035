import {Component, NgZone, OnInit}                 from '@angular/core';
import {TournamentService}                         from '@bluewater/services';
import {ActivatedRoute}                            from '@angular/router';
import {ComponentBase}                             from '@gx/core';
import {distinct}                                  from 'rxjs/operators';
import {TournamentViewModel}                       from '@bluewater/viewmodels';
import {fontRegular, fontSolid, switchToLegacyApp} from '../../../modules';
import {ApiQueryService}                           from '../../../services/api/api-query.service';
import {ApiCommandService}                         from '../../../services/api/api-command.service';

@Component({
    selector: 'bw-edit-tournament',
    templateUrl: './edit-tournament.component.html',
    styleUrls: ['./edit-tournament.component.scss']
})
export class EditTournamentComponent extends ComponentBase implements OnInit {
    public tournamentId = 0;
    public tournament?: TournamentViewModel;
    public fontSolid = fontSolid;
    public fontRegular = fontRegular;
    public documents: string[] = [];
    public takeAHero: boolean | undefined;

    constructor(private tournamentService: TournamentService,
                private apiQueryService: ApiQueryService,
                private apiCommandService: ApiCommandService,
                private route: ActivatedRoute,
                private zone: NgZone) {
        super();
    }

    ngOnInit(): void {
        this.takeUntilDestroyed(this.route.params)
            .pipe(distinct())
            .subscribe(params => {
                this.tournamentId = +params.tournamentId;
                this.fetchTournamentAsync();
            });
    }

    private async fetchTournamentAsync() {
        const documents = await this.apiQueryService.fileSystemGetFolderContents('documents');
        const tournament = await this.tournamentService.getTournamentAsync(this.tournamentId, true);

        this.zone.run(() => {
            this.documents = documents;
            this.tournament = tournament;
            this.takeAHero = tournament.TakeAHero;
        });
    }

    private async saveAsync() {
        const takeAHero = this.takeAHero || false;
        await this.apiCommandService.updateTournamentTakeAHero(this.tournament!.TournamentId, takeAHero);
    }

    public async close() {
        await this.saveAsync();
        switchToLegacyApp();
    }
}
