import {Component} from '@angular/core';
import {Meta, MetaDefinition, Title}        from '@angular/platform-browser';
import {DeviceDetectorService}              from 'ngx-device-detector';

@Component({
    selector: 'bw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public classes = 'main-body';

    constructor(
        private titleService: Title,
        private meta: Meta,
        deviceDetectorService: DeviceDetectorService
    ) {
        titleService.setTitle('Bluewater Tournament Registration');
        const noTranslateMetaTag: MetaDefinition = {
            name: 'google',
            content: 'notranslate'
        };
        meta.addTag(noTranslateMetaTag);

        const userAgent = deviceDetectorService.userAgent;
        const classes = ['main-body'];

        if (deviceDetectorService.isMobile(userAgent)) {
            classes.push('mobile');
        }

        if (deviceDetectorService.isTablet(userAgent)) {
            classes.push('tablet');
        }

        if (deviceDetectorService.isDesktop(userAgent)) {
            classes.push('desktop');
        }

        this.classes = classes.join(' ');
    }
}
