import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges, Output, EventEmitter,
} from '@angular/core';
import * as fontAwesome from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'gx-icon-button',
  templateUrl: './gx-icon-button.component.html',
  styleUrls: ['./gx-icon-button.component.scss'],
})
export class GxIconButtonComponent implements OnInit, OnChanges {
  @Input() icon = fontAwesome.faSquare;
  @Input() text = '';
  @Input() lineBreakAfterIcon = false;
  @Input() color = '';
  @Input() visible = true;
  @Input() enabled = true;

  @Output() buttonClick = new EventEmitter<void>();

  public colorStyle: {} | { color: string; 'border-color': string } = {};
  public fontAwesome = fontAwesome;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      const newColor = changes.color.currentValue;

      if (newColor) {
        this.colorStyle = {
          color: newColor,
          'border-color': newColor
        };
      } else {
        this.colorStyle = {};
      }
    }
  }

  public onClick(): void {
    this.buttonClick.emit();
  }
}
