import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { PopupMessageService } from '@bluewater/services';

@Component({
    selector: 'bw-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginUnsuccessful = false;

    constructor(
        private currentUserService: AuthenticationService,
        private router: Router,
        private popupMessageService: PopupMessageService
    ) { }

    ngOnInit(): void {
        if (window !== window.top) {
            window!.top!.location = window.location;
        }
    }

    public async SignInAsync(username: string, password: string): Promise<void> {
        const user = await this.currentUserService.authenticateUser(username, password);
        if (user) {
            const startPage = user.StartPage;
            if (startPage.startsWith('/app')) {
                this.router.navigateByUrl('/home');
            } else {
                document.location.href = startPage;
            }

            this.loginUnsuccessful = false;
        } else {
            this.loginUnsuccessful = true;
            alert('Login Failed');
        }
    }

    public async CreateAccountAsync(username: string, password: string): Promise<void> {
        await this.currentUserService.logoutUserAsync();

        if (!username && !password) {
            this.popupMessageService.showWarning({ title: "Create Account Error", message: "Please enter a username\nand a password\nand click Create Account" });
            return;
        }

        if (!username) {
            this.popupMessageService.showWarning({ title: "Create Account Error", message: "Please enter a username\nand click Create Account" });
            return;
        }

        if (!password) {
            this.popupMessageService.showWarning({ title: "Create Account Error", message: "Please enter a password\nand click Create Account" });
            return;
        }

        if (await this.currentUserService.createUserAsync(username, password)) {
            this.router.navigateByUrl('/home');
            this.loginUnsuccessful = false;
        } else {
            this.loginUnsuccessful = true;
            alert('Create Account Failed');
        }
    }
}
