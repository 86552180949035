import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'gx-theme-banner',
  templateUrl: './gx-theme-banner.component.html',
  styleUrls: ['./gx-theme-banner.component.scss']
})
export class GxThemeBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
