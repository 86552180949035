import {SysDate} from '../../../../view-models';

export const maxBirthdateYear = (new Date()).getFullYear();
export const minBirthdateYear = 1900;

export const activeTimeFormat = Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York'
});

export function computeAge(effectiveDate: SysDate, birthDate: SysDate): number {
    const effectiveDateBeforeBirthdate = effectiveDate.Month < birthDate.Month ||
        (effectiveDate.Month === birthDate.Month && effectiveDate.Day < birthDate.Day);

    const offset = effectiveDateBeforeBirthdate ? 1 : 0;
    return effectiveDate.Year - birthDate.Year - offset;
}

export function formatDate(sysdate: SysDate): string {
    if (!sysdate) {
        return '';
    }

    const {Year, Month, Day} = sysdate;

    const year = Year || '';
    const month = Month || '';
    const day = Day || '';

    if (year || month || day) {
        return `${month}/${day}/${year}`;
    }

    return '';
}

export function dateHasValue(date: SysDate) {
    if (!date) {
        return false;
    }

    return !!(date.Year || date.Month || date.Day);
}

export function dateHasValidValue(date: SysDate) {
    if (!dateHasValue(date)) {
        return false;
    }
    const validMonth = date.Month > 1 && date.Month < 13;
    const validDay = date.Day > 0 && date.Day < 32;
    const validYear = date.Year > minBirthdateYear && date.Year < maxBirthdateYear;

    return validYear && validMonth && validDay;
}

function getPart(parts: Intl.DateTimeFormatPart[], part: Intl.DateTimeFormatPartTypes) {
    return +parts.find(p => p.type === part)!.value;
}

export function getSysdateNow() {
    const dateNow = new Date().getTime();
    const dateNowParts = activeTimeFormat.formatToParts(dateNow);
    const getDtPart = (p: Intl.DateTimeFormatPartTypes) => getPart(dateNowParts, p);

    const sysDateNow: SysDate = {
        Year: getDtPart('year'),
        Month: getDtPart('month'),
        Day: getDtPart('day')
    };

    return sysDateNow;
}

export function dateIsPast(date: SysDate, sysDateNowValue?: SysDate) {
    const sysDateNow = sysDateNowValue || getSysdateNow();

    if (sysDateNow.Year === date.Year) {
        if (sysDateNow.Month === date.Month) {
            return sysDateNow.Day > date.Day;
        } else {
            return sysDateNow.Month > date.Month;
        }
    } else {
        return sysDateNow.Year > date.Year;
    }
}
