import {Pipe, PipeTransform} from '@angular/core';
import {SysDate}             from '@bluewater/viewmodels';
import {dateHasValue}        from '../../components/registration/anglers/extensions/date-functions';

const months = [
    'NULL',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

@Pipe({
    name: 'gxSysdate'
})
export class GxSysdatePipe implements PipeTransform {

    transform(value: SysDate): string {
        if (!dateHasValue(value)) {
            return '';
        }
        return months[value.Month] + ' ' + value.Day + ', ' + value.Year;
    }
}
