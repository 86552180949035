import {Component}     from '@angular/core';
import {GxTextInputComponent} from './gx-text-input.component';

@Component({
    selector: 'gx-tel-input',
    templateUrl: './gx-text-input.component.html',
    styleUrls: ['./gx-text-input.component.scss']
})
export class GxTelephoneInputComponent<T extends string | number> extends GxTextInputComponent<T> {
    public type = 'tel';
    public pattern = "[0-9]{3}-[0-9]{3}-[0-9]{4}";

    constructor() {
        super();
    }

}
