import {Injectable}                                            from '@angular/core';
import {Integer}                                               from '../core/models';
import {ApiQueryService}                                       from './api/api-query.service';
import {TournamentEligibilityUpdateModel, TournamentViewModel} from '@bluewater/viewmodels';
import {ApiCommandService}                                     from './api/api-command.service';

@Injectable({
    providedIn: 'root'
})
export class TournamentService {
    private tournaments: { [id: number]: TournamentViewModel } = {};

    constructor(private apiQueryService: ApiQueryService,
                private apiCommandService: ApiCommandService) {}

    public async getTournamentAsync(tournamentId: Integer, forceRefresh = false): Promise<TournamentViewModel> {
        if (!tournamentId) {
            throw new Error('Argument not specified tournamentId');
        }

        const tournament = this.tournaments[tournamentId];

        if (tournament && !forceRefresh) {
            return tournament;
        }

        return (this.tournaments[tournamentId] = await this.apiQueryService.getTournamentAsync(tournamentId));
    }

    public getTournamentListAsync() {
        return this.apiQueryService.getTournamentListAsync();
    }

    public saveTournamentEligibilityAsync(tournamentId: Integer, eligibilityIdList: TournamentEligibilityUpdateModel[]): Promise<void> {
        return this.apiCommandService.saveTournamentEligibilityAsync(tournamentId, eligibilityIdList);
    }

    public saveTournamentCalcuttaEligibilityAsync(
        tournamentId: Integer,
        calcuttaProductId: Integer,
        eligibilityId?: Integer): Promise<void> {
        return this.apiCommandService.saveTournamentCalcuttaEligibilityAsync(tournamentId, calcuttaProductId, eligibilityId);
    }
}
