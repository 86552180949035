<div class="name">
    Name
</div>

<div class="captainOwner">
</div>

<div class="status">
    Status
</div>

<div [ngClass]="{ participantRelease: true, isNotValid: !anglerWaiverValid}">
    Click here to view the<br/>
    <a [href]="anglerWaiver" target="release">Participant Release</a>&nbsp;*
</div>

<button style="visibility: hidden">Edit</button>
<button style="visibility: hidden">Delete</button>
