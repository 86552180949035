import {Component, Input, NgZone, OnChanges, OnInit}               from '@angular/core';
import {ValuedComponentBase}                                       from '../../../core/component-base/valued-component-base';
import {CategorizedLookup, GuidString, Integer, YesNo}             from '@gx/core';
import {LookupService}                                             from '../../../services/lookup.service';
import {TeamListItemViewModel, TeamViewModel, TournamentViewModel} from '@bluewater/viewmodels';
import {RegistrationService}                                       from '@bluewater/services';

@Component({
    selector: 'bw-boat-details',
    templateUrl: './boat-details.component.html',
    styleUrls: ['./boat-details.component.scss']
})
export class BoatDetailsComponent extends ValuedComponentBase<TeamViewModel> implements OnInit, OnChanges {
    @Input() public tournamentName = '';
    @Input() public tournament?: TournamentViewModel;
    @Input() public userId?: GuidString;

    public lookups: CategorizedLookup[] = [];
    public copyFromPreviousPossible = false;
    public copyFromPreviousEnabled = false;
    public showCopyFromPrevious = false;
    public previousTeams: TeamListItemViewModel[] = [];
    public YesNo = YesNo;
    public takeAHero?: YesNo;

    constructor(private lookupService: LookupService,
                private registrationService: RegistrationService,
                private zone: NgZone) {
        super();

        this.validate = team => this.checkIsValid(team);
    }

    ngOnInit(): void {
        const value = this.value!.TakeAHero;

        if (value === undefined) {
            this.takeAHero = undefined;
        } else {
            this.takeAHero = value ? YesNo.Yes : YesNo.No;
        }

        this.initLookupsAsync();
    }

    private async getPreviousTeamListAsync(): Promise<void> {
        const previousTeams = await this.registrationService.getTeamListAsync(this.userId!);
        this.zone.run(() => {
            this.previousTeams = previousTeams.filter(t => t.TournamentId !== this.value!.TournamentId);
        });
    }

    private async initLookupsAsync(): Promise<void> {
        const lookups = await this.lookupService.getLookups();
        this.zone.run(() => {
            this.lookups = lookups;

            const team = this.value!;
            this.copyFromPreviousPossible = team.HasPreviousBoats && !team.EntryComplete;
            this.copyFromPreviousEnabled = this.copyFromPreviousPossible && !team.BoatName;
        });

        if (this.copyFromPreviousPossible) {
            await this.getPreviousTeamListAsync();
        }
    }

    private checkIsValid(team: TeamViewModel | null | undefined): boolean {
        if (!team) {
            return true;
        }

        const {
            BoatName,
            BoatMake,
            EngineMake,
            NumOfEngines,
            BoatLength,
            ElectronicsMake
        } = team;

        return !!(
            BoatName &&
            BoatMake &&
            EngineMake &&
            NumOfEngines &&
            BoatLength &&
            ElectronicsMake
        );
    }

    private emitIsValid(isValid: boolean) {
        this.isValid.emit(isValid);
    }

    public updateIsValid() {
        this.emitIsValid(this.validate!(this.value));
    }

    public updateValue() {
        this.value!.TakeAHero = (this.takeAHero === YesNo.Yes);
        super.updateValue();
    }

    public updateBoatName() {
        const team = this.value!;
        const copyFromPreviousEnabledNewValue = !team.BoatName && this.copyFromPreviousPossible;
        this.copyFromPreviousEnabled = copyFromPreviousEnabledNewValue;

        this.updateValue();
    }

    public showCopyFromPreviousClick() {
        this.showCopyFromPrevious = true;
        this.copyFromPreviousEnabled = false;
    }

    public hideCopyFromPreviousClick() {
        this.showCopyFromPrevious = false;
        this.copyFromPreviousEnabled = true;
    }

    public async loadPreviousBoatAsync(tournamentId: Integer, userId: GuidString) {
        const previousBoat = await this.registrationService.getRegistrationAsync(tournamentId, userId);

        const {
            BoatName,
            BoatMake,
            EngineMake,
            NumOfEngines,
            BoatLength,
            EnginePower,
            EngineSize,
            ElectronicsMake,
            TeamId
        }
            = previousBoat;

        this.zone.run(() => {
            const team = this.value!;
            this.value = {
                ...team,
                BoatName,
                BoatMake,
                EngineMake,
                NumOfEngines,
                BoatLength,
                EnginePower,
                EngineSize,
                ElectronicsMake,
                PreviousTeamId: TeamId
            };

            this.updateValue();

            this.copyFromPreviousEnabled = false;
            this.copyFromPreviousPossible = false;
            this.showCopyFromPrevious = false;
        });

        await this.registrationService.saveAsync(this.value!);
    }
}
