import {Component, Input, NgZone, OnChanges, OnInit, SimpleChanges}                             from '@angular/core';
import {TeamCalcuttaViewModel, TeamViewModel, TournamentCalcuttaViewModel, TournamentViewModel} from '@bluewater/viewmodels';
import {ValuedComponentBase}                                                                    from '../../../core/component-base/valued-component-base';
import {TournamentService}                                                                      from '../../../services/tournament.service';
import {fontSolid, fontRegular}                                                                 from '../../../modules';
import {YesNo}                                                                                  from '@gx/core';

@Component({
    selector: 'bw-calcuttas',
    templateUrl: './calcuttas.component.html',
    styleUrls: ['./calcuttas.component.scss']
})
export class CalcuttasComponent extends ValuedComponentBase<TeamViewModel> implements OnInit, OnChanges {
    @Input() tournamentName = '';
    @Input() tournament?: TournamentViewModel;

    private isLoaded = false;

    public calcuttaViews: {
        tournamentCalcutta: TournamentCalcuttaViewModel,
        teamCalcutta: TeamCalcuttaViewModel
    }[] = [];

    public selectedCalcuttas: { [key: number]: boolean } = {};
    public fontSolid = fontSolid;
    public fontRegular = fontRegular;

    constructor(private tournamentService: TournamentService,
                private ngZone: NgZone) {
        super();
    }

    ngOnInit(): void {
        if (this.value && this.tournament) {
            this.initializeSelectedCalcuttas();
        }
    }

    private initializeSelectedCalcuttas() {
        const tournamentCalcuttas = this.tournament!.Calcuttas;
        const teamCalcuttas = this.value!.Calcuttas;
        const eligibilityIds = this.value!.Eligibilities
            .filter(el => el.Answer === YesNo.Yes)
            .map(el => el.EligibilityId);

        const calcuttaViews = tournamentCalcuttas
            .map(tournamentCalcutta => ({
                tournamentCalcutta,
                teamCalcutta: teamCalcuttas.find(tc => tc.CalcuttaProductId === tournamentCalcutta.CalcuttaProductId)!
            }))
            .filter(cv => !cv.tournamentCalcutta.EligibilityId || eligibilityIds.includes(cv.tournamentCalcutta.EligibilityId));

        this.calcuttaViews = calcuttaViews;

        const selectedCalcuttas: { [key: number]: boolean } = {};
        calcuttaViews.forEach(cv => selectedCalcuttas[cv.tournamentCalcutta.CalcuttaProductId] = !!cv.teamCalcutta);
        this.selectedCalcuttas = selectedCalcuttas;
    }

    public ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (changes.value || changes.tournament) {
            this.initializeSelectedCalcuttas();
        }
    }

    public flip(calcuttaProductId: number) {
        const selectedCalcuttas = {
            ...this.selectedCalcuttas,
            [calcuttaProductId]: !this.selectedCalcuttas[calcuttaProductId]
        };

        const tournamentCalcuttaProductIds = this.tournament?.Calcuttas.map(c => c.CalcuttaProductId)!;
        const selectedCalcuttaProductIds = tournamentCalcuttaProductIds.filter(tc => selectedCalcuttas[tc]);

        let teamCalcuttas = this.value!.Calcuttas;
        const teamCalcuttaProductIds = teamCalcuttas.map(c => c.CalcuttaProductId);

        const adds = selectedCalcuttaProductIds.filter(c => !teamCalcuttaProductIds.includes(c));
        const removes = teamCalcuttaProductIds.filter(c => !selectedCalcuttaProductIds.includes(c));

        teamCalcuttas.push(...adds.map(CalcuttaProductId => ({CalcuttaProductId, IsPaid: false})));
        teamCalcuttas = teamCalcuttas.filter(tc => !removes.includes(tc.CalcuttaProductId));

        this.value!.Calcuttas = teamCalcuttas;
        this.selectedCalcuttas = selectedCalcuttas;
    }
}
