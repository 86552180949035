import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AnglersComponent}                                              from '../anglers.component';
import {ValuedComponentBase}                                           from '../../../../core/component-base/valued-component-base';
import {TeamAnglerViewModel, TournamentViewModel}                      from '@bluewater/viewmodels';
import {CategorizedLookup, YesNo}                                      from '@gx/core';
import {
    isNewUnmodifiedAngler,
    validateAngler,
    phoneNumberIsValidUnitedStatesNumber
}                                                                      from '../extensions/functions';
import {computeAge, dateHasValidValue, formatDate}                     from '../extensions/date-functions';
import {states}                                                        from './state-data';

@Component({
    selector: 'bw-angler-edit',
    templateUrl: './angler-edit.component.html',
    styleUrls: ['./angler-edit.component.scss']

})
export class AnglerEditComponent extends ValuedComponentBase<TeamAnglerViewModel> implements OnChanges {
    @Input() public tournament!: TournamentViewModel;
    @Input() public lookups: CategorizedLookup[] = [];
    public IsSka?: number;
    public DateOfBirth?: string;
    public validationMessage = '';
    public isDone = false;
    public states = states;

    constructor(public parent: AnglersComponent,
                private cdr: ChangeDetectorRef) {

        super();

        this.validate = a => this.validateAngler();

        this.takeUntilDestroyed(this.valueChange).subscribe(angler => {
            this.handleAnglerChange(angler);
        });
    }

    private handleAnglerChange(angler: TeamAnglerViewModel | null | undefined): void {
        if (!angler) {
            return;
        }

        const isNewAngler = isNewUnmodifiedAngler(angler);
        if (isNewAngler) {
            this.DateOfBirth = '';
        } else {
            this.IsSka = angler.IsSka ? YesNo.Yes : YesNo.No;
            this.DateOfBirth = formatDate(angler.DateOfBirth);
        }
    }

    private validateAngler() {
        const angler = this.value!;
        this.validationMessage = '';
        const validationMessages: string[] = [];

        if (!angler && isNewUnmodifiedAngler(angler)) {
            return false;
        }

        validateAngler(angler, validationMessages);

        this.validationMessage = validationMessages.join('\n');
        this.cdr.detectChanges();

        // is valid if no validation messages
        return !validationMessages.length;
    }

    public updateProperty(property: string = 'any'): void {
        const angler = this.value!;

        const state = angler.State || '';
        if (state.length > 2) {
            const findState = this.states.find(st => st.includes(state));
            if (findState) {
                angler.State = findState[1];
            } else {
                const tryFindState = this.states.find(st => st[0].startsWith(state));
                if (tryFindState) {
                    angler.State = tryFindState[1];
                }
            }
        }

        switch (property) {
            case 'IsSka':
                const isSka = this.IsSka;
                angler.IsSka = (isSka === YesNo.Yes);
                break;
            case 'DateOfBirth':
                const dobString = this.DateOfBirth || '';
                const dateOfBirthValues = dobString.split(/[^\w_]/g).filter(v => v);

                if (dateOfBirthValues.length === 3) {
                    let year = +dateOfBirthValues[2];

                    if (year < 30) {
                        year = (2000 + year);
                    } else if (year < 100) {
                        year = (1900 + year);
                    }

                    const month = this.parseMonth(dateOfBirthValues[0]);
                    const day = +dateOfBirthValues[1];

                    const dateOfBirth = {
                        Year: year,
                        Month: month,
                        Day: day
                    };

                    this.value!.DateOfBirth = dateOfBirth;

                    if (dateHasValidValue(dateOfBirth)) {

                        const normalizedDateOfBirth = formatDate(dateOfBirth);

                        if (this.DateOfBirth !== normalizedDateOfBirth) {
                            this.DateOfBirth = normalizedDateOfBirth;
                        }

                        if (!angler.Age) {
                            const tournamentStartDate = this.tournament.StartDate;
                            const anglerDob = angler.DateOfBirth;
                            const age = computeAge(tournamentStartDate, anglerDob);
                            angler.Age = age;
                        }
                    }
                } else {
                    this.value!.DateOfBirth = {
                        Year: 0,
                        Month: 0,
                        Day: 0
                    };
                }
                break;
        }

        this.updateValue();

        if (this.isDone && this.isValidSnapshot) {
            this.parent.exitEditMode();
        } else {
            this.cdr.detectChanges();
        }
    }

    public phoneNumberIsValidUnitedStatesNumber(phoneNumber?: string): boolean {
        return phoneNumberIsValidUnitedStatesNumber(phoneNumber);
    }

    private parseMonth(month: string): number {
        if (!month) {
            return 0;
        }

        if (isNaN(+month)) {
            const monthStr = month.toLowerCase();
            return 1 + ['ja', 'f', 'mar', 'apr', 'may', 'jun', 'jul', 'au', 's', 'o', 'n', 'd']
                .findIndex(firstLetters => monthStr.startsWith(firstLetters));
        } else {
            return +month;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        this.updateValue();
    }

    private sortAnglers(): void {
        const anglers = [...this.parent.value!.Anglers];

        function getSortString(angler: TeamAnglerViewModel): string {
            const isBoth = angler.IsCaptain && angler.IsOwner;

            const both = isBoth ? 'a' : 'b';
            const cap = angler.IsCaptain ? 'a' : 'b';
            const owner = angler.IsOwner ? 'a' : 'b';
            const name = angler.LastName + ' ' + angler.FirstName;

            return both + cap + owner + name;
        }

        anglers.sort((a, b) => {
            const left = getSortString(a);
            const right = getSortString(b);
            if (left === right) { return 0; }
            return left > right ? 1 : -1;
        });

        this.parent.value!.Anglers = anglers;
    }

    public setCaptain(): void {
        this.parent.value!.Anglers.forEach(angler => angler.IsCaptain = false);
        this.value!.IsCaptain = true;
        this.sortAnglers();
    }

    public setOwner() {
        this.parent.value!.Anglers.forEach(angler => angler.IsOwner = false);
        this.value!.IsOwner = true;
        this.sortAnglers();
    }

    public async tryExitEditModeAsync(): Promise<void> {
        this.isDone = true;
        this.updateProperty();
    }

    public exitEditModeAsync() {
        this.parent.exitEditMode();
    }

    public async deleteAngler() {
        await this.parent.deleteAngler(this.value!);
        await this.exitEditModeAsync();
    }
}
