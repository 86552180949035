import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges
}                                                       from '@angular/core';
import {ValuedComponentBase}                            from '../../component-base/valued-component-base';
import {Integer, Guid, CategorizedLookup, SimpleLookup} from '../../models';

export enum MultiInputStyle {
    Dropdown = 'Dropdown',
    Radio = 'Radio',
    YesNo = 'YesNo'
}

@Component({
    selector: 'gx-multi-input',
    templateUrl: './gx-multi-input.component.html',
    styleUrls: ['./gx-multi-input.component.scss']
})
export class GxMultiInputComponent extends ValuedComponentBase<Integer>
    implements OnInit, OnChanges {
    @Input() inputStyle = MultiInputStyle.Dropdown;
    @Input() categoryKey: string = '';
    @Input() options: CategorizedLookup[] = [];
    @Input() excludedOptions: number[] = [];

    public emitOnChange = true;
    public MultiInputStyle = MultiInputStyle;
    public compiledOptions: SimpleLookup[] = [];
    public uniqueId = Guid.NewGuid();

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.categoryKey || changes.options || changes.excludedOptions) {
            const excludedOptions = this.excludedOptions || [];

            this.compiledOptions = this.options
                .filter(opt => opt.CategoryKey === this.categoryKey)
                .filter(opt => !excludedOptions.some(xo => xo === opt.Id) );
        }

        if (changes.value) {
            if (changes.value.currentValue === undefined) {
                this.value = 0;
            }
        }
    }

    public updateValue(): void {
        this.value = +(this.value!) || 0;
        super.updateValue();
    }

    ngOnInit(): void {
    }
}
