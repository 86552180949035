<div class="name">
    {{ angler.FirstName }}
    {{ angler.LastName }}
</div>

<div>
    {{ angler.IsCaptain ? 'Captain' : '' }}
    {{ angler.IsCaptain && angler.IsOwner ? '/' : ' '}}
    {{ angler.IsOwner ? 'Owner' : ''}}
</div>

<div>
    {{ angler.Age || (angler.DateOfBirth | gxSysdate) }}
    {{ angler.Email }}
</div>

<button (click)="copyAnglerAsync()">Copy</button>
<button (click)="ignoreAnglerAsync()">Ignore</button>
