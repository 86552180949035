import {Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ComponentBase}                                                                 from './component-base';

@Component({
    template: ''
})
// tslint:disable-next-line:component-class-suffix
export abstract class ValuedComponentBase<T> extends ComponentBase implements OnChanges {
    @HostBinding('class.isValid') public isValidSnapshot = false;
    @HostBinding('class.isInvalid') public isInvalidSnapshot = true;

    @Input() public emitOnChange = true;
    @Input() public required = false;
    @Input() public validate?: (val?: T) => boolean;
    @Input() public value?: T;

    @Output() public valueChange = new EventEmitter<T>();
    @Output() public isValid = new EventEmitter<boolean>();

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            this.emitValue(changes.value.currentValue);
        }
    }

    public updateValue() {
        this.emitValue(this.value);
    }

    private emitValue(value: T | undefined) {
        const isValid = this.isValueValid(value);

        this.isValidSnapshot = isValid;
        this.isInvalidSnapshot = !isValid;

        this.isValid.emit(isValid);
        this.valueChange.emit(value);
    }

    private isValueValid(value: T | undefined): boolean {
        if (this.validate) {
            if (!this.validate(value)) {
                return false;
            }
        }

        if (this.required) {
            return this.hasValue(value);
        } else {
            return true;
        }
    }

    /** @description variable contains a valid value */
    // tslint:disable-next-line:no-any
    private hasValue(value: any): boolean {
        return !!value && value !== '0';
    }
}

