<ng-container *ngIf="value && eligibilities">
    <bw-step-header
            stepName="Eligibility"
            [stepNumber]="2"
            [totalStepCount]="5"
            [tournamentName]="tournamentName"
    >
    </bw-step-header>

    <div *ngIf="validationMessages?.length" class="validation-message">
        <div *ngFor="let message of validationMessages">
            {{ message.message }}
            <span class="more">
            {{ message.subMessages && message.subMessages.length ? ' [ More... ]' : '' }}
        </span>
            <div class="subMessage" *ngIf="message.subMessages && message.subMessages.length">
                <div *ngFor="let subMessage of message.subMessages">
                    {{ subMessage }}
                </div>
            </div>
        </div>
    </div>

    <bw-eligibility-block *ngFor="let elig of eligibilities"
                          [(value)]="elig.Answer"
                          [name]="elig.Name"
                          (valueChange)="updateValue()"
    >{{ elig.Description}}
    </bw-eligibility-block>

    <div class="signature-block">
        <div class="signature-text">
            Please enter your full name as an acknowledgement for the information provided.
        </div>

        <div class="signature">
            Signature:
            <gx-text-input
                    [(value)]="value.EligibilitySignature" [required]="true"
                    (valueChange)="updateValue()"
            ></gx-text-input>
        </div>
    </div>
</ng-container>
