import {Injectable}               from '@angular/core';
import {ApiQueryService}          from './api/api-query.service';
import {ApiCommandService}        from './api/api-command.service';
import {ApplicationUserViewModel} from '@bluewater/viewmodels';
import {Router}                   from '@angular/router';
import {sleepAsync}               from '../core/functions';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public applicationUser?: ApplicationUserViewModel;
    public searchResultsCache: {
        [key: string]: ApplicationUserViewModel[]
    } = {};
    private searchBegun: string[] = [];

    constructor(private apiCommandService: ApiCommandService,
                private router: Router,
                private apiQueryService: ApiQueryService) {}

    public async authenticateUser(username: string, password: string): Promise<ApplicationUserViewModel> {
        this.applicationUser = await this.apiCommandService.authenticateUserAsync(username, password);
        return this.applicationUser;
    }

    public logoutUserAsync() {
        return this.apiCommandService.logoutUserAsync();
    }

    public createUserAsync(username: string, password?: string) {
        return this.apiCommandService.createUserAsync(username, password);
    }

    public async getCurrentUserAsync(): Promise<ApplicationUserViewModel> {
        const currentUser = await this.apiQueryService.getCurrentUserAsync();
        if (!currentUser) {
            this.router.navigateByUrl('/');
        }
        return currentUser;
    }

    public async searchUsersAsync(userSearch: string, tournamentId: number): Promise<ApplicationUserViewModel[]> {
        if (!userSearch || userSearch.length < 3) {
            return [];
        }

        await sleepAsync(userSearch.length * 20);

        userSearch = userSearch.toLowerCase();

        const searchResultsCache = this.searchResultsCache;
        const searchValue = userSearch.substr(0, 3);
        const searchKey = tournamentId + '::' + searchValue;

        if (!searchResultsCache[searchKey]) {
            if (this.searchBegun.includes(searchKey)) {
                await sleepAsync(500);
            }
            if (!searchResultsCache[searchKey]) {
                searchResultsCache[searchKey] =
                    await this.apiQueryService.getUserListAsync(searchValue, tournamentId);
            }
        }

        return this.filterSearch(
            userSearch,
            searchResultsCache[searchKey]
        );
    }

    private filterSearch(userSearchLowerCase: string, results: ApplicationUserViewModel[]) {
        return results.filter(user => user.UserName.toLowerCase().includes(userSearchLowerCase));
    }

    public createNewUserAsync(email: string) {
        return this.apiCommandService.createUserAsync(email);
    }

    public changePassword(newPassword: string) {
        return this.apiCommandService.changePassword(newPassword);
    }
}
