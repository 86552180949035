import {Component, NgZone, OnInit}                                     from '@angular/core';
import {TeamListItemViewModel, TournamentListItemViewModel}            from '@bluewater/viewmodels';
import {Router}                                                        from '@angular/router';
import {fontSolid, fontRegular}                                        from '../../modules';
import {TournamentService, RegistrationService, AuthenticationService} from '../../services';
import {dateIsPast, getSysdateNow}                                     from '../registration/anglers/extensions/date-functions';

type TeamPlusTournament = {
    team: TeamListItemViewModel,
    tournament: TournamentListItemViewModel
};

@Component({
    selector: 'bw-owner-home',
    templateUrl: './owner-home.component.html',
    styleUrls: ['./owner-home.component.scss']
})
export class OwnerHomeComponent implements OnInit {
    public fontSolid = fontSolid;
    public fontRegular = fontRegular;

    public tournamentList: TournamentListItemViewModel[] = [];
    public teamList: TeamListItemViewModel[] = [];
    public teamTourneyList: TeamPlusTournament[] = [];
    public isLoaded = false;
    public passwordChangeInProgress = false;
    public newPassword = "";

    constructor(
        private authenticationService: AuthenticationService,
        private tournamentService: TournamentService,
        private teamService: RegistrationService,
        private router: Router,
        private zone: NgZone) {
    }

    ngOnInit(): void {
        this.OnInit();
    }

    public async logout() {
        await this.authenticationService.logoutUserAsync();
        this.router.navigateByUrl('/');
    }

    private async OnInit(): Promise<void> {
        const userId = (await this.authenticationService.getCurrentUserAsync()).UserId;
        const teamList = await this.teamService.getTeamListAsync(userId);
        const teamTourneyIds = teamList.map(tl => tl.TournamentId);
        const tournamentList = (await this.tournamentService.getTournamentListAsync())
            .filter(t => !dateIsPast(t.RegistrationDeadline));

        const teamTourneyList: TeamPlusTournament[] = teamList
            .map(team => ({
                team,
                tournament: tournamentList
                    .find(tourney => tourney.TournamentId === team.TournamentId)!
            }))
            .filter(team => team.tournament);

        this.zone.run(() => {
            this.teamList = teamList;
            this.tournamentList = tournamentList
                .filter(t => !teamTourneyIds.includes(t.TournamentId));

            this.teamTourneyList = teamTourneyList;
            this.isLoaded = true;
        });
    }

    public navigateToBoatScreen(tournamentId: number) {
        this.router.navigateByUrl('/register/' + tournamentId + '/boat');
    }

    public showHelp() {
        window.open('https://www.bluewatermovements.com/faq/', '_blank');
    }

    public startChangePassword() {
        this.passwordChangeInProgress = true;
    }

    public async changePassword(newPassword: string) {
        await this.authenticationService.changePassword(newPassword);
        this.passwordChangeInProgress = false;
    }
}

