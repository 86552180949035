import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {fontRegular, fontSolid} from '../../../modules';
import {TournamentRoute} from '../models';

@Component({
  selector: 'bw-tournament-footer',
  templateUrl: './tournament-footer.component.html',
  styleUrls: ['./tournament-footer.component.scss'],
})
export class TournamentFooterComponent implements OnInit {
  public fontSolid = fontSolid;
  public fontRegular = fontRegular;

  @Input() public enableNext: boolean | null = false;
  @Input() public nextStep?: TournamentRoute;
  @Input() public previousStep?: TournamentRoute;

  @Output() public helpClick = new EventEmitter<void>();
  @Output() public closeClick = new EventEmitter<void>();
  @Output() public previousClick = new EventEmitter<void>();
  @Output() public nextClick = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }
}
