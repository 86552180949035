import {Component, Input, OnInit} from '@angular/core';
import {YesNo}                    from '@gx/core';
import {ValuedComponentBase}      from '../../../../core/component-base/valued-component-base';

@Component({
    selector: 'bw-eligibility-block',
    templateUrl: './eligibility-block.component.html',
    styleUrls: ['./eligibility-block.component.scss']
})
export class EligibilityBlockComponent extends ValuedComponentBase<YesNo | undefined> implements OnInit {
    @Input() public name: string = '';

    constructor() { super(); }

    ngOnInit(): void {
    }

}
