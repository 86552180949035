// tslint:disable:no-any

import {Pipe, PipeTransform} from '@angular/core';

function getPropertyValueString<T>(obj: T, key: string): string {
    const keyedObj = obj as { [key: string]: any };
    return String(keyedObj[key]);
}

@Pipe({
    name: 'gxPropertyEqualFilter'
})
export class GxPropertyEqualFilterPipe implements PipeTransform {
    transform<T>(values: T[], filter: { property: string, eq?: string, ne?: string }): T[] {
        const {property, eq, ne} = filter;

        if (eq) {
            values = values.filter(value => getPropertyValueString(value, property) === String(eq));
        }

        if (ne) {
            values = values.filter(value => getPropertyValueString(value, property) !== String(ne));
        }

        return values;
    }
}
