import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CategorizedLookup, GuidString, Integer } from '../../core/models';
import { httpGet, httpPost } from './functions';
import {
    ApplicationUserViewModel,
    EligibilityViewModel,
    TeamListItemViewModel,
    TeamUpdateModel,
    TeamViewModel,
    TournamentListItemViewModel,
    TournamentViewModel,
    OrderViewModel, TeamAnglerViewModel, SecurityHintInformation, FeeViewModel
} from '@bluewater/viewmodels';
import { HttpErrorHandlerService } from '../http-error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class ApiQueryService {
    constructor(
        private httpErrorHandlerService: HttpErrorHandlerService,
        private http: HttpClient
    ) {
    }

    private get<T>(relativeUrl: string): Promise<T> {
        return this.httpErrorHandlerService.popWarningOnExceptionAsync(
            httpGet<T>(this.http, relativeUrl)
        );
    }

    private post<T>(relativeUrl: string, body: any): Promise<T> {
        return this.httpErrorHandlerService.popWarningOnExceptionAsync(
            httpPost<T>(this.http, relativeUrl, body)
        );
    }

    public getSecurityHints() {
        return this.get<SecurityHintInformation>('/Authentication/SecurityHints');
    }

    public getTeamAsync(tournamentId: number, userId: GuidString): Promise<TeamViewModel> {
        return this.get<TeamViewModel>('/Team/' + tournamentId + '/' + userId);
    }

    public getLookups(): Promise<CategorizedLookup[]> {
        return this.get<CategorizedLookup[]>('/Lookups');
    }

    public getFees(): Promise<FeeViewModel[]> {
        return this.get<FeeViewModel[]>('/Lookups/Fees');
    }

    public getTournamentListAsync(): Promise<TournamentListItemViewModel[]> {
        return this.get<TournamentListItemViewModel[]>('/Tournament');
    }

    public getTournamentAsync(tournamentId: Integer): Promise<TournamentViewModel> {
        return this.get<TournamentViewModel>('/Tournament/' + tournamentId);
    }

    public async getTeamListAsync(userId: GuidString): Promise<TeamListItemViewModel[]> {
        return this.get<TeamListItemViewModel[]>('/Team/' + userId);
    }

    public async getCurrentUserAsync(): Promise<ApplicationUserViewModel> {
        return this.get<ApplicationUserViewModel>('/Authentication');
    }

    public async getUserListAsync(searchValue: string, tournamentId: number): Promise<ApplicationUserViewModel[]> {
        return this.get<ApplicationUserViewModel[]>(`/functions/usersearch?searchValue=${searchValue}&tournamentId=${tournamentId}`);
    }

    public async getEligibilityList(): Promise<EligibilityViewModel[]> {
        return this.get<EligibilityViewModel[]>('/eligibilities');
    }

    public async getTeamOrdersAsync(currentTournamentId: Integer, userId: GuidString): Promise<OrderViewModel[]> {
        return this.get<OrderViewModel[]>(`/Team/${currentTournamentId}/${userId}/Orders`);
    }

    public getCopyableAnglers(currentTournamentId: Integer, userId: GuidString) {
        return this.get<TeamAnglerViewModel[]>(`/Team/${currentTournamentId}/${userId}/copyable-anglers`);
    }

    public fileSystemGetFolders() {
        return this.get<string[]>('/Storage/');
    }

    public fileSystemGetFolderContents(folderName: string) {
        return this.get<string[]>(`/Storage/${folderName}`);
    }
}

