import {Component, HostBinding, HostListener, Input, NgZone, OnInit} from '@angular/core';
import {AnglersComponent}                                            from '../../anglers.component';
import {TeamAnglerViewModel}                                         from '@bluewater/viewmodels';
import {CategorizedLookup, Integer}                                  from '@gx/core';
import {LookupService, RegistrationService}                          from '@bluewater/services';
import {createNewAngler}                                             from '../../extensions/functions';

@Component({
    selector: 'bw-angler-copy',
    templateUrl: './angler-copy.component.html',
    styleUrls: [
        './angler-copy.component.scss',
        '../angler-item.component.scss'
    ]
})
export class AnglerCopyComponent implements OnInit {
    @Input() angler: TeamAnglerViewModel = createNewAngler();
    @Input() teamId: Integer = 0;

    public show = true;
    public lookups: CategorizedLookup[] = [];

    constructor(
        private lookupService: LookupService,
        private registrationService: RegistrationService,
        private zone: NgZone,
        public parent: AnglersComponent) { }

    ngOnInit(): void {
        this.initLookupsAsync();
    }

    private async initLookupsAsync(): Promise<void> {
        const lookups = await this.lookupService.getLookups();
        this.zone.run(() => this.lookups = lookups);
    }

    public async ignoreAnglerAsync(): Promise<void> {
        const anglerId = this.angler.AnglerId;
        const teamId = this.teamId;

        await this.registrationService.ignorePreviousAngler(teamId, anglerId);
        this.parent.previousAnglers = this.parent.previousAnglers.filter(angler => angler !== this.angler);
    }

    public async copyAnglerAsync(): Promise<void> {
        this.zone.run(() => this.show = false);

        const anglerId = this.angler.AnglerId;
        const teamId = this.teamId;

        this.angler.PreviousAnglerId = anglerId;
        this.angler.AnglerId = 0;

        this.parent.previousAnglers = this.parent.previousAnglers.filter(angler => angler !== this.angler);

        this.parent.addAngler(this.angler);
        await this.registrationService.saveAsync(this.parent.value!);
        await this.registrationService.ignorePreviousAngler(teamId, anglerId);
    }
}
