import {Component, NgZone, OnInit}                 from '@angular/core';
import {TournamentService}                         from '@bluewater/services';
import {ActivatedRoute}                            from '@angular/router';
import {ComponentBase, Integer}                    from '@gx/core';
import {distinct}                                  from 'rxjs/operators';
import {TournamentViewModel}                       from '@bluewater/viewmodels';
import {fontRegular, fontSolid, switchToLegacyApp} from '../../../modules';
import {ApiQueryService}                           from '../../../services/api/api-query.service';
import {ApiCommandService}                         from '../../../services/api/api-command.service';

@Component({
    selector: 'bw-edit-tournament-waiver',
    templateUrl: './edit-tournament-waiver.component.html',
    styleUrls: ['./edit-tournament-waiver.component.scss']
})
export class EditTournamentWaiverComponent extends ComponentBase implements OnInit {
    public tournamentId = 0;
    public tournament?: TournamentViewModel;
    public fontSolid = fontSolid;
    public fontRegular = fontRegular;
    public selectedWaiver = '';
    public documents: string[] = [];

    constructor(private tournamentService: TournamentService,
                private apiQueryService: ApiQueryService,
                private apiCommandService: ApiCommandService,
                private route: ActivatedRoute,
                private zone: NgZone) {
        super();
    }

    ngOnInit(): void {

        this.takeUntilDestroyed(this.route.params)
            .pipe(distinct())
            .subscribe(params => {
                this.tournamentId = +params.tournamentId;
                this.fetchTournamentAsync();
            });
    }

    private async fetchTournamentAsync() {
        const documents = await this.apiQueryService.fileSystemGetFolderContents('documents');

        const tournament = await this.tournamentService.getTournamentAsync(this.tournamentId, true);
        const selectedItems: { [key: number]: boolean } = {};

        this.zone.run(() => {
            this.documents = documents;
            this.tournament = tournament;
            this.selectedWaiver = tournament.AnglerWaiver;
        });
    }

    private async saveAsync() {
        await this.apiCommandService.updateTournamentWaiverAsync(this.tournament!.TournamentId, this.selectedWaiver);
    }

    public async close() {
        await this.saveAsync();
        switchToLegacyApp();
    }

    public selectDocument(document: string) {
        this.selectedWaiver = document;
    }
}
