<ng-template #thenBlock>Select</ng-template>
<ng-template #elseBlock>Clear</ng-template>

<div *ngIf="inputStyle === MultiInputStyle.Dropdown">
    <select [(ngModel)]="value" [required]="required" (blur)="updateValue()"
    >
        <option class="placeholder" value="0" [selected]="value == 0 || value == undefined">
            <span *ngIf="value == 0 || value == undefined; then thenBlock else elseBlock"></span>
        </option>
        <option *ngFor="let option of compiledOptions"
         [value]="option.Id"
         [selected]="value == option.Id"
         >
            {{ option.Label }}
        </option>
    </select>
</div>
<div class="input-yesno" *ngIf="inputStyle === MultiInputStyle.YesNo">
    <label *ngFor="let option of compiledOptions" [for]="uniqueId + '-' + option.Id">
        <input [name]="uniqueId"
               [required]="required"
               type="radio"
               [value]="option.Id"
               [id]="uniqueId + '-' + option.Id"
               [checked]="option.Id == value"
               (click)="(value = option.Id) && updateValue()"
        />
        {{option.Label}}
    </label>
</div>
<div class="input-radio" *ngIf="inputStyle === MultiInputStyle.Radio">

    <div *ngFor="let option of compiledOptions">
        <input [name]="uniqueId" [required]="required" type="radio" [value]="option.Id"
            [id]="uniqueId + '-' + option.Id" [checked]="option.Id == value"
            (click)="(value = option.Id) && updateValue()" />
        <label [for]="uniqueId + '-' + option.Id">
            {{option.Label}}
        </label>
    </div>
</div>