import {Component, HostListener, Input, NgZone, OnInit} from '@angular/core';
import {AnglersComponent}                               from '../../anglers.component';
import {TeamAnglerViewModel}                            from '@bluewater/viewmodels';
import {CategorizedLookup}                              from '@gx/core';
import {LookupService, RegistrationService}             from '@bluewater/services';
import {createNewAngler}                                from '../../extensions/functions';

@Component({
    selector: 'bw-angler',
    templateUrl: './angler.component.html',
    styleUrls: ['../angler-item.component.scss']
})
export class AnglerComponent implements OnInit {
    @Input() angler: TeamAnglerViewModel = createNewAngler();

    public lookups: CategorizedLookup[] = [];

    constructor(
        private lookupService: LookupService,
        private zone: NgZone,
        private registrationService: RegistrationService,
        public parent: AnglersComponent) { }

    ngOnInit(): void {
        this.initLookupsAsync();
    }

    private async initLookupsAsync(): Promise<void> {
        const lookups = await this.lookupService.getLookups();
        this.zone.run(() => this.lookups = lookups);
    }

    public editAngler(): void {
        this.parent.currentAngler = this.angler;
        this.parent.editAnglerMode = true;
    }

    public updateProperty() {
        this.parent.updateIsValid();
    }

    public deleteAngler() {
        this.parent.deleteAngler(this.angler);
    }
}
