import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LookupService, PopupMessageService, RegistrationService } from '@bluewater/services';
import { CreditCardPayment, FeeViewModel, ManualPaymentUpdateModel, OrderViewModel } from '@bluewater/viewmodels';
import { CategorizedLookup, ComponentBase, YesNo } from '@gx/core';
import { UsageContext } from 'src/app/core/constants/usage-context';
import { getErrorString, isObject, truncDecimalPlaces } from 'src/app/core/functions';


@Component({
    selector: 'bw-checkout-order',
    templateUrl: './checkout-order.component.html',
    styleUrls: [
        '../checkout.component.scss', // share styling with parent by including parent's style here
        './checkout-order.component.scss'
    ],
    animations: [
        trigger('fade', [
            transition('Hide => *, void => *', [style({ opacity: 0, 'font-size': '1px' }), animate(400, style({ opacity: 1, 'font-size': '1em' }))]),
            transition('* => Hide, * => void', [style({ opacity: 1, 'font-size': '1em' }), animate(400, style({ opacity: 0, 'font-size': '1px' }))]),
        ])
    ],
})
export class CheckoutOrderComponent extends ComponentBase implements OnInit, OnChanges {

    @Input() order?: OrderViewModel & { PlannedPaymentTypeId?: number };
    @Output() orderUpdated = new EventEmitter<void>();

    private Categories = {
        SalesTax: 5,
        ConvenienceFee: 6
    };

    public paymentMethod = '';
    public paymentMethodIncludesConvenienceFee = false;
    public get paymentMethodSelected() { return !!this.paymentMethod; };
    public get paymentMethodIsCcard() { return this.paymentMethod === 'CCARD'; };
    public showCreditCardPaymentScreen = false;
    public showAdminPaymentScreen = false;
    public get paymentScreenIsVisible() { return this.showAdminPaymentScreen || this.showCreditCardPaymentScreen; };

    public creditCardInfo: CreditCardPayment = this.createEmptyCreditCardPayment();
    public manualPaymentInfo: ManualPaymentUpdateModel = this.createEmptyManualPaymentInfo();

    public orderStatus: "Over" | "Paid" | "Due" | "" = "";
    public convenienceFeeDisplay: "Show" | "Hide" | "TBD" = "Show";
    public lookups: CategorizedLookup[] = [];
    public fees: FeeViewModel[] = [];
    public orderBalance = 0;
    public orderConvenienceFee = 0;
    public isStaffContext = false;
    public usageContext?: UsageContext;

    public get orderDetailsAll() { return this.order ? this.order.OrderDetails : [] };
    public get orderPaymentBalance() { return this.order ? this.order.OrderPayments.reduce((sum, item) => sum + item.PaymentAmount, 0) : 0; };

    public get convenienceFee(): FeeViewModel { return this.fees.find(fee => fee.CategoryId === this.Categories.ConvenienceFee) ?? { Rate: 0, Name: '', Category: '', CategoryId: 0 } };

    constructor(
        private lookupService: LookupService,
        private activatedRoute: ActivatedRoute,
        private registrationService: RegistrationService,
        private popupMessageService: PopupMessageService,
        private zone: NgZone
    ) {
        super();

        this.takeUntilDestroyed(this.activatedRoute.data).subscribe(data => {
            this.usageContext = data.usageContext;
            this.isStaffContext = (this.usageContext === UsageContext.usageContextStaff);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.order) {
            this.orderBalance = 0;
        }

        if (changes.order && this.order) {
            this.orderBalance = this.order.Balance;

            if (this.order.Balance < 0) {
                this.orderStatus = "Over";
            } else if (this.order.PaidDate) {
                this.orderStatus = "Paid";
            } else {
                this.orderStatus = "Due";
            }
        }

        this.convenienceFeeDisplay = "Hide";

        if (this.order && this.orderStatus === "Due") {
            const paymentTotal = this.order.OrderPayments.reduce((sum, current) => sum + current.PaymentAmount, 0);
            const detailsTotal = this.order.OrderDetails.reduce((sum, current) => sum + current.Price, 0);
            const taxesTotal = this.order.OrderDetails.filter(od => od.CategoryId === this.Categories.SalesTax).reduce((sum, current) => sum + current.Price, 0);
            const balance = detailsTotal - paymentTotal;

            let feeAbleTotal = detailsTotal - taxesTotal;
            if (feeAbleTotal > balance) {
                feeAbleTotal = balance;
            }

            this.orderConvenienceFee = truncDecimalPlaces(this.convenienceFee.Rate * feeAbleTotal);

            if (this.paymentMethodSelected && this.paymentMethodIncludesConvenienceFee) {
                this.orderBalance = balance + this.orderConvenienceFee;
                this.convenienceFeeDisplay = "Show";
            } else {
                this.orderBalance = balance;
                this.convenienceFeeDisplay = this.paymentMethodSelected ? "Hide" : "TBD";
            }
        }
    }

    ngOnInit(): void {
        this.onInitAsync();
    }


    private async onInitAsync(): Promise<void> {
        this.lookups = await this.lookupService.getLookups();
        this.fees = await this.lookupService.getFees();
    }

    public getLookup(id?: number): CategorizedLookup {
        if (!id) {
            return this.lookupService.EmptyLookup;
        }
        const value = this.lookups.find(l => l.Id === id);
        return value || this.lookupService.EmptyLookup;
    }

    public updateProperty(value: number) {
        const lookupValue = this.getLookup(value).Value;

        this.paymentMethod = lookupValue;
        this.paymentMethodIncludesConvenienceFee = this.paymentMethodIsCcard;

        this.ngOnChanges({});
    }

    private createEmptyCreditCardPayment(): CreditCardPayment {
        return {
            Amount: 0,
            BillingAddress1: '',
            CVVCode: '',
            Cellular: '',
            City: '',
            CreditCardNumber: '',
            EMail: '',
            ExpirationDate: '',
            FirstNameOnCreditCard: '',
            LastNameOnCreditCard: '',
            OrderId: 0,
            State: '',
            Telephone: '',
            Zip: '',
            AgreeToPayConvenienceFee: YesNo.No,
            ConvenienceFeeAmount: 0
        };
    }

    private createDebugCreditCardPayment(): CreditCardPayment {
        return {
            Amount: 0,
            EMail: 'john.smith@example.com',
            BillingAddress1: '1 Billing Address',
            City: 'AnyCity',
            State: 'FL',
            Zip: '34777',
            CVVCode: '555',
            CreditCardNumber: '4007000000027',
            ExpirationDate: '0325',
            FirstNameOnCreditCard: 'John',
            LastNameOnCreditCard: 'Smith',
            OrderId: 0,
            Telephone: '402-555-1234',
            Cellular: '402-555-1235',
            AgreeToPayConvenienceFee: YesNo.No,
            ConvenienceFeeAmount: 0
        };
    }

    public applyPaymentPreset(amount: number, paymentTypeId: number): void {
        this.manualPaymentInfo.Amount = +amount.toFixed(2);

        // don't change payment type if already selected
        if (!this.manualPaymentInfo.PaymentTypeId) {
            this.manualPaymentInfo.PaymentTypeId = paymentTypeId;
        } else if (paymentTypeId === 0) {
            this.manualPaymentInfo.PaymentTypeId = 0;
        }
    }

    private createEmptyManualPaymentInfo(): ManualPaymentUpdateModel {
        return { Amount: 0, Comment: '', OrderId: 0, PaymentTypeId: 0, TeamId: 0 };
    }

    public async processCreditCardPaymentAsync(): Promise<void> {
        const order = this.order!;

        this.creditCardInfo.OrderId = order.Id;
        this.creditCardInfo.Amount = order.Balance;
        this.creditCardInfo.ConvenienceFeeAmount = this.orderConvenienceFee;

        try {
            await this.registrationService.applyCreditCardPayment(this.creditCardInfo, true);
        }
        catch (err) {
            this.showErrorIfLocal("Error Applying Payment", err);

            return;
        }

        this.orderUpdated.next();

        this.zone.run(() => {
            this.showCreditCardPaymentScreen = false;
            this.creditCardInfo = this.createEmptyCreditCardPayment();
        });
    }

    public clearPayment(): void {
        this.manualPaymentInfo = this.createEmptyManualPaymentInfo();
        this.showAdminPaymentScreen = false;
    }

    public async applyManualPaymentAsync(): Promise<void> {
        const manualPaymentInfo = this.manualPaymentInfo;
        const selectedOrder = this.order!;

        const teamId = selectedOrder.TeamId;

        manualPaymentInfo.TeamId = teamId;
        manualPaymentInfo.OrderId = selectedOrder.Id;

        try {
            await this.registrationService.applyManualPayment(manualPaymentInfo, true);
        }
        catch (err) {
            this.showErrorIfLocal("Error Applying Payment", err);
        }

        this.orderUpdated.next();

        this.zone.run(() => {
            this.clearPayment();
        });
    }

    private showErrorIfLocal(title: string, err: any) {
        if (isObject(err) && err.cause === "LocalValidation") {
            const message = getErrorString(err);
            const warningMessage = { message, title };

            this.popupMessageService.showWarning(warningMessage);
        }
    }
}