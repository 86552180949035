import {Component, Input, OnInit} from '@angular/core';
import {ValuedComponentBase}      from '../../component-base/valued-component-base';

@Component({
    selector: 'gx-text-input',
    templateUrl: './gx-text-input.component.html',
    styleUrls: ['./gx-text-input.component.scss']
})
export class GxTextInputComponent<T extends string | number> extends ValuedComponentBase<T>
    implements OnInit {

    @Input() public autocomplete?: string;
    @Input() public tooltip?: string;
    @Input() public type = 'text';
    
    public pattern?: string;

    constructor() {
        super();
        this.emitOnChange = false;
    }

    ngOnInit(): void {
    }
}
