import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute}                                   from '@angular/router';
import {ApiCommandService}                                from '../../../services/api/api-command.service';
import {ApiQueryService}                                  from '../../../services/api/api-query.service';
import * as fontAwesome                                   from '@fortawesome/free-regular-svg-icons';
import {sleepAsync}                                from '../../../core/functions';
import {fontSolid, fontRegular, switchToLegacyApp} from 'src/app/modules';

function getBase64DataUri(file: Blob): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = ev => resolve(ev.target!.result as string);
        reader.onerror = ev => reject(ev);
        reader.readAsDataURL(file);
    });
}

interface SelectedFile {
    file: File;
    status: string;
    fileUri?: string;
    completed: boolean;
}

@Component({
    selector: 'bw-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {
    @ViewChild('inputFile') inputFile?: ElementRef;
    @ViewChild('folderName') folderName?: ElementRef;

    public fontSolid = fontSolid;
    public fontRegular = fontRegular;

    public folder = '';
    public selectedFiles: SelectedFile[] = [];
    public fontAwesome = fontAwesome;
    public folderMessage = '';

    public folders: { name: string }[] = [];
    public files: {
        name: string,
        url: string
    }[] = [];

    public newItem = '';

    constructor(route: ActivatedRoute,
                private apiQueryService: ApiQueryService,
                private apiCommandService: ApiCommandService,
                private zone: NgZone) {
        this.folder = route.snapshot.params.folder;

        switch (this.folder) {
            case 'documents':
                this.folderMessage = 'Upload angler waivers for tournaments.  In the tournament setup, you can select one of these documents to serve as the waiver document for a tournament.';
                break;
        }
    }

    ngOnInit(): void {
        this.onInitAsync();
    }

    private async onInitAsync() {
        this.files = [];
        this.folders = [];

        if (this.folder) {
            await this.updateFilesAsync();
        } else {
            await this.updateFoldersAsync();
        }
    }

    private async updateFoldersAsync() {
        const folders = await this.apiQueryService.fileSystemGetFolders();
        const folderObjects = folders.map(name => ({name}));

        this.zone.run(() => this.folders = folderObjects);
    }

    private async updateFilesAsync() {
        const files = await this.apiQueryService.fileSystemGetFolderContents(this.folder);
        const fileObjects = files
            .filter(f => !f.startsWith('Directory: '))
            .map(url => ({
                name: url.split('/').pop() || '',
                url
            }));

        this.zone.run(() => this.files = fileObjects);
    }

    public setNewValue(event: Event) {
        const element = event.target as unknown as HTMLInputElement;
        this.newItem = element.value;
    }

    public async createNewFolder(newItem: string) {
        await this.apiCommandService.createFolderAsync(newItem);
        await this.updateFoldersAsync();
        this.newItem = '';
        this.folderName!.nativeElement!.value = '';
    }

    public async handleFiles(event: Event) {
        const zone = this.zone;
        const target = event.target as unknown as HTMLInputElement;
        const files = Array.from(target.files!);
        const selectedFiles = files.map<SelectedFile>(pf => ({
            file: pf,
            status: 'Processing...',
            completed: false
        }));

        const updateSelectedFiles = (newSelectedFiles: SelectedFile[]) =>
            zone.run(() => this.selectedFiles = newSelectedFiles);

        updateSelectedFiles(selectedFiles);

        for (const selectedFile of selectedFiles) {
            selectedFile.fileUri = await getBase64DataUri(selectedFile.file);
            selectedFile.status = 'Awaiting upload...';
            updateSelectedFiles([...selectedFiles]);
        }

    }

    public async uploadFilesAsync() {
        const selectedFiles = this.selectedFiles.filter(sf => !sf.completed && sf.fileUri);

        const updateSelectedFiles = (newSelectedFiles: SelectedFile[]) =>
            this.zone.run(() => this.selectedFiles = newSelectedFiles);

        if (!this.selectedFiles.length) {
            return;
        }

        const promises = selectedFiles.map(async selectedFile => {
            selectedFile.status = 'Uploading...';
            updateSelectedFiles([...selectedFiles]);

            await this.apiCommandService.uploadFileAsync(
                this.folder,
                selectedFile.file.name,
                selectedFile.fileUri!
            );

            selectedFile.status = 'Complete';
            selectedFile.completed = true;
            updateSelectedFiles([...selectedFiles]);
        });

        await Promise.all(promises);
        await this.updateFilesAsync();
        await sleepAsync(2000);
        this.inputFile!.nativeElement!.value = '';
        this.selectedFiles = [];
    }

    public async deleteFile(filename: string) {
        await this.apiCommandService.deleteFileAsync(this.folder, filename);
        await this.updateFilesAsync();
    }

    public async close() {
        switchToLegacyApp();
    }
}
