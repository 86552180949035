<div class="boat-details" *ngIf="value">

    <bw-step-header
            stepName="Boat Details"
            [stepNumber]="1"
            [totalStepCount]="5"
            [tournamentName]="tournamentName"
    >
    </bw-step-header>

    <gx-button class="copyPreviousButton" *ngIf="copyFromPreviousEnabled"
               (click)="showCopyFromPreviousClick()"
    >Copy from Previous
    </gx-button>
    <div *ngIf="showCopyFromPrevious" class="centered">
        Select a Previous Boat to Copy or <a (click)="hideCopyFromPreviousClick()">Cancel</a><br/>
        <br/>
        <div class="copyFromPreviousSection">
            <div class="previousTeamItem" *ngFor="let teamItem of previousTeams">
                <button class="teamItem">
                    <div (click)="loadPreviousBoatAsync(teamItem.TournamentId, teamItem.UserId )">
                        <b>{{ teamItem.Summary }}</b><br/>
                        {{ teamItem.TournamentDescription }}<br/>
                        {{ teamItem.StartDate| gxSysdate  }}<br/>
                        {{ teamItem.EndDate| gxSysdate  }}<br/>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="!showCopyFromPrevious">
        <div>Boat Number: {{ value.BoatNumber || 'Assigned at checkout'}}</div>
        <div class="fields">
            <section>
                <gx-labeled-input label="Boat Name" [required]="true">
                    <gx-text-input [(value)]="value.BoatName"
                                   [required]="true"
                                   (valueChange)="updateBoatName()"
                    ></gx-text-input
                    >
                </gx-labeled-input>
                <gx-labeled-input *ngIf="tournament!.TakeAHero"
                                  label="Will your team be participating in the Take a Hero Fishing Day?"
                                  [required]="true">
                    <gx-multi-input-yes-no [(value)]="takeAHero" (valueChange)="updateValue()">
                    </gx-multi-input-yes-no>
                </gx-labeled-input>
            </section>
            <section>
                <gx-labeled-input label="Boat Make" [required]="true">
                    <gx-text-input
                            [(value)]="value.BoatMake"
                            [required]="true"
                            (valueChange)="updateValue()"
                    ></gx-text-input>
                </gx-labeled-input>
                <gx-labeled-input label="Engine Make" [required]="true">
                    <gx-text-input
                            [(value)]="value.EngineMake" [required]="true"
                            (valueChange)="updateValue()"
                    ></gx-text-input
                    >
                </gx-labeled-input>
                <gx-labeled-input label="Number of Engines" [required]="true">
                    <gx-multi-input-dropdown
                            [(value)]="value.NumOfEngines" [required]="true"
                            categoryKey="NumOfEngines"
                            [options]="lookups"
                            (valueChange)="updateValue()"
                    ></gx-multi-input-dropdown>
                </gx-labeled-input>
            </section>
            <section>
                <gx-labeled-input label="Boat Length (ft)" [required]="true">
                    <gx-text-input
                            [(value)]="value.BoatLength" [required]="true"
                            (valueChange)="updateValue()"
                    ></gx-text-input
                    >
                </gx-labeled-input>
                <gx-labeled-input label="Engine Power">
                    <gx-multi-input-dropdown
                            [(value)]="value.EnginePower"
                            categoryKey="EnginePower"
                            [options]="lookups"
                            (valueChange)="updateValue()"
                    ></gx-multi-input-dropdown>
                </gx-labeled-input>
                <gx-labeled-input label="Engine Size">
                    <gx-text-input
                            [(value)]="value.EngineSize"
                            (valueChange)="updateValue()"
                    ></gx-text-input
                    >
                </gx-labeled-input>
                <gx-labeled-input label="Electronics Make" [required]="true">
                    <gx-multi-input-dropdown
                            [(value)]="value.ElectronicsMake"
                            [required]="true"
                            categoryKey="ElectronicsMake"
                            [options]="lookups"
                            (valueChange)="updateValue()"
                    ></gx-multi-input-dropdown>
                </gx-labeled-input>
            </section>
        </div>
    </div>
</div>
