// tslint:disable:no-any
import {HttpClient, HttpEvent, HttpHeaders, HttpParams}  from '@angular/common/http';
import {environment} from '../../../environments/environment';

const apiUrl = environment.api;

export function httpGet<T>(http: HttpClient, relativeUrl: string): Promise<T> {
    return http.get<T>(apiUrl + relativeUrl).toPromise();
}

export function httpDelete<T>(http: HttpClient, relativeUrl: string): Promise<T> {
    return http.delete<T>(apiUrl + relativeUrl).toPromise();
}

export function httpPost<T>(http: HttpClient, relativeUrl: string, body: any): Promise<T> {
    return http.post<T>(apiUrl + relativeUrl, body).toPromise();
}

export function httpPut<T>(http: HttpClient, relativeUrl: string, body: any): Promise<T> {
    return http.put<T>(apiUrl + relativeUrl, body).toPromise();
}

export function httpPatch<T>(http: HttpClient, relativeUrl: string, body: any): Promise<T> {
    return http.patch<T>(apiUrl + relativeUrl, body).toPromise();
}
