<div *ngIf="tournament">
    <bw-administration-header>
        Edit Tournament Eligibility
    </bw-administration-header>
    <main>
        <div>
            <h2>Tournament: {{ tournament.Description}}</h2>
            <br/>
            <br/>
            <div *ngFor="let elig of eligibilityList">
                <input type="checkbox"
                       value="1"
                       [checked]="selectedItems[elig.EligibilityId]"
                       (change)="updateSelectedItems(elig.EligibilityId, $event)"
                /> {{ elig.Name }}
            </div>
        </div>
    </main>
    <footer>
        <gx-icon-button
                [enabled]="buttonsEnabled"
                [icon]="fontSolid.faSave"
                text="Save"
                (buttonClick)="saveAsync()"
        ></gx-icon-button>

        <gx-icon-button
                [icon]="fontSolid.faDoorClosed"
                [enabled]="buttonsEnabled"
                text="Save and Close"
                (buttonClick)="close()"
        ></gx-icon-button>
    </footer>
</div>
