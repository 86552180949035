<div class="checkout-component" *ngIf="value && tournament && loaded">
    <bw-step-header stepName="Checkout" [stepNumber]="5" [totalStepCount]="5" [tournamentName]="tournamentName">
    </bw-step-header>

    <section class="boat-name">
        <label>Boat Name <a routerLink="../boat">edit boat details</a></label>
        <div>{{ value.BoatName }} <b style="font-weight: 100;">{{ value.BoatNumber ? '-' : ''}}</b> {{
            value.BoatNumber }}</div>

        <ng-container *ngIf="isStaffContext">
            &nbsp;&middot;&nbsp;
            <gx-button (click)="setEntryComplete(!value.EntryComplete)">Administratively Force Entry
                to {{ value.EntryComplete ? "Uncompleted" : "Completed" }}
            </gx-button>
            &nbsp;&middot;&nbsp;
            <gx-text-input [(value)]="newBoatNumber" (focusin)="boatNumberChanging=true"
                (focusout)="boatNumberChanging=false"></gx-text-input>
            <gx-button [enabled]="!!newBoatNumber || boatNumberChanging" (click)="updateBoatNumber(newBoatNumber)">
                Administratively Update Boat Number
            </gx-button>
        </ng-container>
    </section>

    <section class="anglers">
        <label>Anglers <a routerLink="../anglers">edit anglers</a></label>
        <div class="anglers column-collection">
            <div class="row-collection">
                <div class="angler-name">Name</div>
                <div class="angler-role">Role</div>
            </div>
            <div class="row-collection" *ngFor="let participant of value.Anglers">
                <div class="angler-name">{{ participant.FirstName }} {{participant.LastName}}</div>
                <div class="angler-role">{{ participant.IsCaptain ? 'Captain' : ''}}{{
                    participant.IsCaptain && participant.IsOwner ? '/' : ''
                    }}{{ participant.IsOwner ? 'Owner' : ''}}</div>
            </div>
        </div>
    </section>

    <section class="eligibility">
        <label>Eligibility <a routerLink="../eligibility">edit eligibility</a></label>
        <div class="eligibilities column-collection">
            <div class="row-collection">
                <div class="eligible-name">Name</div>
                <div class="eligible-yesno">Eligible?</div>
                <div class="eligible-description">Requirements</div>
            </div>
            <div class="row-collection" *ngFor="let eligible of eligibilities">
                <div class="eligible-name">{{eligible.Name}}</div>
                <div class="eligible-yesno">{{YesNo[eligible.Answer!]}}</div>
                <div class="eligible-description">{{eligible.Description}}</div>
            </div>
        </div>
    </section>

    <section class="calcuttas">
        <label>Calcuttas <a routerLink="../calcuttas">edit calcuttas</a></label>
        <div class="calcuttas column-collection">
            <div class="row-collection">
                <div class="calcutta-name">Name</div>
                <div class="price">Price</div>
                <div class="paid">Is Paid?</div>

            </div>
            <div class="row-collection" *ngFor="let calcutta of calcuttaInfo">
                <div class="calcutta-name">{{calcutta.tournamentCalcutta.Name}}</div>
                <div class="price">{{calcutta.tournamentCalcutta.Price}}</div>
                <div class="paid">{{calcutta.teamCalcutta.IsPaid ? 'Yes' : 'No'}}</div>
            </div>
        </div>
    </section>



    <section class="orders-and-payments">

        <label>Purchases</label>
        Team Balance: {{ orderBalanceTotal | currency }}

        <label>Orders</label>

        <div class="orders-and-payments" style="height: 150px;" *ngIf="!ordersLoaded">
            Loading...
        </div>
        <div *ngIf="ordersLoaded" class="orders column-collection">
            <div style="flex-direction: column;" class="row-collection order" *ngFor="let order of orders">
                <bw-checkout-order [order]="order" (orderUpdated)="updateRegistrationAndOrders()">
                </bw-checkout-order>
            </div>
        </div>
    </section>
</div>