export enum TournamentRoute {
  Unknown = '',
  SelectUser = 'select-user',
  Boat = 'boat',
  Eligibility = 'eligibility',
  Anglers = 'anglers',
  Calcuttas = 'calcuttas',
  Checkout = 'checkout',
}

export const TournamentRoutes = [
    TournamentRoute.Boat,
    TournamentRoute.Eligibility,
    TournamentRoute.Anglers,
    TournamentRoute.Calcuttas,
    TournamentRoute.Checkout
];
