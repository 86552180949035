<div class="root">
    <div class="login__screen">
        <img class="login__logo" src="/app/assets/images/bluewater-logo.png"/>

        <div class="login__form">
            <a
                    class="help"
                    target="_blank"
                    title="Need assistance? Click here and we'll be in touch."
                    href="https://www.bluewatermovements.com/faq/"
            ><i>?</i></a
            >

            <svg
                    class="headerText"
                    viewBox="0 0 60 20"
                    xmlns="http://www.w3.org/2000/svg"
            >
                <style>
                    text {
                        font-size: 5px;
                    }
                </style>
                <text x="50%" y="33%" dominant-baseline="middle" text-anchor="middle">
                    Bluewater Movements
                </text>
                <text x="50%" y="66%" dominant-baseline="middle" text-anchor="middle">
                    Tournament Registration
                </text>
            </svg>

            <input
                    #username
                    name="username"
                    class="textBoxStyle"
                    type="text"
                    placeholder="Email"
            />
            <input
                    #password
                    name="password"
                    class="textBoxStyle"
                    type="password"
                    placeholder="Password"
            />

            <div *ngIf="loginUnsuccessful">
                Your login was not successful.  Please try again or create a new account.
            </div>

            <div class="buttons">
                <button type="submit" (click)="SignInAsync(username.value, password.value)">Sign In</button>
                <button type="submit" (click)="CreateAccountAsync(username.value, password.value)">Create Account</button>
            </div>

            <a class="forgotPassword" href="/ForgotPassword.aspx"
            >Forgot your password?</a
            >
        </div>
    </div>
</div>
