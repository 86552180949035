<div *ngIf="value && lookups.length">
    <div class="buttons">
        <button *ngIf="!value.IsCaptain" (click)="setCaptain()">Set To Captain</button>
        <button *ngIf="!value.IsOwner" (click)="setOwner()">Set To Owner</button>
        <div class="button-spacer">&nbsp;</div>
        <button (click)="deleteAngler()">Delete</button>
        <button class="doneButton" (click)="tryExitEditModeAsync()">Save</button>
        <div class="button-spacer">&nbsp;</div>
        <div class="button-spacer">&nbsp;</div>
    </div>
    <div class="angler-edit"
    >
        <div class="validation-message">{{ validationMessage }}</div>
        <div class="left">
            <gx-labeled-input label="First Name" [required]="true">
                <gx-text-input
                        [(value)]="value.FirstName" [required]="true"
                        (valueChange)="updateProperty()"
                ></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="Last Name" [required]="true">
                <gx-text-input
                        [(value)]="value.LastName" [required]="true"
                        (valueChange)="updateProperty()"
                ></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="Address" [required]="value.IsOwner || value.IsCaptain || false">
                <gx-text-input
                        [(value)]="value.Address" [required]="value.IsOwner || value.IsCaptain || false"
                        (valueChange)="updateProperty()"
                ></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="City" [required]="value.IsOwner || value.IsCaptain || false">
                <gx-text-input
                        [(value)]="value.City" [required]="value.IsOwner || value.IsCaptain || false"
                        (valueChange)="updateProperty()"
                ></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="State" [required]="value.IsOwner || value.IsCaptain || false">
                <select #state [(ngModel)]="value.State" (change)="updateProperty()">
                    <option value=""></option>
                    <option *ngFor="let state of states" [value]="state[1]">{{ state[0]}}</option>
                </select>
            </gx-labeled-input>

            <gx-labeled-input label="Zip" [required]="value.IsOwner || value.IsCaptain || false">
                <gx-text-input
                        [(value)]="value.Zip" [required]="value.IsOwner || value.IsCaptain || false"
                        (valueChange)="updateProperty()"
                ></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="Phone" [required]="value.IsOwner || value.IsCaptain || false">
                <gx-tel-input
                        [(value)]="value.CellPhone" [required]="value.IsOwner || value.IsCaptain || false"
                        [validate]="phoneNumberIsValidUnitedStatesNumber"
                        (valueChange)="updateProperty()"
                ></gx-tel-input>
            </gx-labeled-input>

            <!--            <gx-labeled-input label="Home Phone" [required]="true">
                            <gx-tel-input
                                    [(value)]="value.HomePhone" [required]="true"
                                    [validate]="phoneNumberIsValidUnitedStatesNumber"
                                    (valueChange)="updateProperty()"
                            ></gx-tel-input>
                        </gx-labeled-input>-->

        </div>
        <div class="right">
            <gx-labeled-input label="Email" [required]="value.IsOwner || value.IsCaptain || false">
                <gx-text-input
                        [(value)]="value.Email" [required]="value.IsOwner || value.IsCaptain || false"
                        (valueChange)="updateProperty()"
                ></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="Angler Status" [required]="true">
                <gx-multi-input-dropdown
                        [(value)]="value.StatusId" [required]="true"
                        categoryKey="AnglerStatus"
                        [options]="lookups"
                        (valueChange)="updateProperty()"
                ></gx-multi-input-dropdown>
            </gx-labeled-input>

            <gx-labeled-input label="TShirt Size" [required]="false">
                <gx-multi-input-dropdown
                        [(value)]="value.TShirtSize" [required]="false"
                        categoryKey="TShirtSize"
                        [options]="lookups"
                        (valueChange)="updateProperty()"
                ></gx-multi-input-dropdown>
            </gx-labeled-input>

            <gx-labeled-input label="Date of Birth (MM/DD/YYYY)" [required]="false">
                <gx-text-input
                        [(value)]="DateOfBirth" [required]="false"
                        (valueChange)="updateProperty('DateOfBirth')"
                ></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="Age" [required]="false">
                <gx-text-input
                        [(value)]="value.Age" [required]="false"
                        (valueChange)="updateProperty()"
                ></gx-text-input>
            </gx-labeled-input>

            <!--            <gx-labeled-input label="SKA Member" [required]="true">
                            <gx-multi-input-yes-no
                                    [(value)]="IsSka" [required]="true"
                                    (valueChange)="updateProperty('IsSKa')"
                            >
                            </gx-multi-input-yes-no>
                        </gx-labeled-input>-->
        </div>
    </div>
</div>
