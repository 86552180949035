export function switchToLegacyApp() {
    // swap angular app with lightswitch app

    if (window === window.top) {
        location.href = '/Classic-Admin';
    }

    // tslint:disable-next-line:no-any
    const parent = window.parent as any;
    parent.legacyFrame.style.display = 'block';
    parent.enhancedFrame.style.display = 'none';

    const switchToLegacyEvent = new Event('switch-to-legacy');
    window.top.dispatchEvent(switchToLegacyEvent);
    location.href = '/app/loading';
}
