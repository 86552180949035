<div *ngIf="order" class="order-row row-collection">
    <div class="order-info">
        <div class="order-date">{{order.OpenDate | gxSysdate}}</div>
        <div class="order-paid" [ngClass]="{isPaid: order.PaidDate, isOverPaid: order.Balance < 0}">
            <svg viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <style>
                    text {
                        font-size: 18px;
                        line-height: 20px;
                        dominant-baseline: hanging;
                    }
                </style>
                <text x="50%" text-anchor="middle">{{orderStatus}}</text>
            </svg>

        </div>
    </div>

    <div class="order-summary">
        <table>
            <tr *ngFor="let row of orderDetailsAll">
                <td>{{row.Name}}</td>
                <td class="price">{{row.Price | currency}}</td>
            </tr>

            <tr *ngIf="convenienceFeeDisplay !== 'Hide'" [@fade]="convenienceFeeDisplay">
                <td>{{convenienceFee.Name}}</td>
                <td class="price" *ngIf="convenienceFeeDisplay === 'TBD'">TBD</td>
                <td class="price" *ngIf="convenienceFeeDisplay === 'Show'">{{orderConvenienceFee | currency}}</td>
            </tr>

            <tr>
                <td colspan="2" class="line">
                    <hr />
                </td>
            </tr>
            <tr *ngFor="let row of order.OrderPayments">
                <td>{{ row.PaymentAmount > 0 ? 'Payment' : 'Credit'}}: {{row.PaymentType}}</td>
                <td [ngClass]="{isCredit: row.PaymentAmount < 0, isDebit: row.PaymentAmount > 0, price: true}">{{-1 *
                    row.PaymentAmount | currency}}</td>
            </tr>
            <tr *ngIf="order.OrderPayments.length">
                <td colspan="2" class="line">
                    <hr />
                </td>
            </tr>
            <tr>
                <td>Total</td>
                <td [ngClass]="{isCredit: orderBalance > 0, isDebit: orderBalance < 0, price: true}">{{ orderBalance |
                    currency }}</td>
            </tr>
        </table>

        <div>&nbsp;</div>

        <div *ngIf="order.Balance > 0">
            Select a Payment Method

            <gx-multi-input-radio [(value)]="order.PlannedPaymentTypeId" [required]="true"
                categoryKey="PlannedPaymentType" [options]="lookups" (valueChange)="updateProperty($event)">
            </gx-multi-input-radio>
        </div>

        <div class="order-actions">
            <div style="text-align: center; background-color: lightyellow; padding: 4px;;"
                *ngIf="paymentMethodSelected && !paymentMethodIsCcard"
                [@fade]="(paymentMethodSelected && !paymentMethodIsCcard) ? 'Show' : 'Hide'">
                Please contact the office at <a href="tel:9547254010">954-725-4010</a> to complete your registration via
                this payment method.
            </div>
            <gx-button *ngIf="!paymentScreenIsVisible && order.Balance > 0" [enabled]="paymentMethodIsCcard"
                (click)="showCreditCardPaymentScreen=true">Pay With Credit Card
            </gx-button>
            <gx-button *ngIf="!paymentScreenIsVisible && isStaffContext" (click)="showAdminPaymentScreen=!showAdminPaymentScreen">Add Payment or
                Credit
            </gx-button>
        </div>

        <div>&nbsp;</div>

        <div *ngIf="showCreditCardPaymentScreen" [@fade]="showCreditCardPaymentScreen ? 'Show' : 'Hide'"
            class="creditCardPaymentScreen">
            <div class="creditCardPaymentScreenColumns">
                <div>
                    <gx-labeled-input label="First Name on Card" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.FirstNameOnCreditCard" [required]="true"
                            autocomplete="given-name"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="Last Name on Card" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.LastNameOnCreditCard" [required]="true"
                            autocomplete="family-name"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="Credit Card Number" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.CreditCardNumber" [required]="true"
                            autocomplete="cc-number"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="Expiration Date" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.ExpirationDate" [required]="true"
                            autocomplete="cc-exp"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="CVV" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.CVVCode" [required]="true" autocomplete="cc-csc">
                        </gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="Cellular" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.Cellular" [required]="true"
                            autocomplete="cellular tel"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="Telephone" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.Telephone" [required]="true" autocomplete="home tel">
                        </gx-text-input>
                    </gx-labeled-input>
                </div>
                <div>
                    <gx-labeled-input label="Billing Address" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.BillingAddress1" [required]="true"
                            autocomplete="billing street-address"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="City" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.City" [required]="true"
                            autocomplete="billing locality"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="State" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.State" [required]="true" autocomplete="billing region">
                        </gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="Zip" [required]="true">
                        <gx-text-input [(value)]="creditCardInfo.Zip" [required]="true"
                            autocomplete="billing postal-code"></gx-text-input>
                    </gx-labeled-input>

                    <gx-labeled-input label="I agree to pay the credit/debit card convenience fee of 3%"
                        [required]="true">
                        <gx-multi-input-yes-no [(value)]="creditCardInfo.AgreeToPayConvenienceFee">
                        </gx-multi-input-yes-no>
                    </gx-labeled-input>
                </div>
            </div>
            <div class="creditCardPaymentScreenButtons">
                <button (click)="processCreditCardPaymentAsync()">Process Credit Card Payment</button>
                <button (click)="showCreditCardPaymentScreen=false">Cancel</button>
            </div>

        </div>

        <div *ngIf="showAdminPaymentScreen" class="adminPaymentScreen">
            <div class="adminPaymentTitle">
                Payment
            </div>

            <div>
                Presets:<br />
                <a style="display: block;" *ngIf="orderBalance > 0" (click)="applyPaymentPreset(order.Balance, 28)">Pay
                    Balance</a>
                <a style="display: block;" *ngIf="orderBalance < 0"
                    (click)="applyPaymentPreset(orderBalance, 75)">Credit the Balance</a>
                <a style="display: block;" *ngIf="orderPaymentBalance"
                    (click)="applyPaymentPreset(-orderPaymentBalance, 75)">Refund Payments</a>
                <a style="display: block;" *ngIf="orderPaymentBalance" (click)="applyPaymentPreset(0, 0)">Clear</a>
            </div>

            <gx-labeled-input label="Amount" [required]="true">
                <gx-text-input [(value)]="manualPaymentInfo.Amount" [required]="true"></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="Comment" [required]="false">
                <gx-text-input [(value)]="manualPaymentInfo.Comment"></gx-text-input>
            </gx-labeled-input>

            <gx-labeled-input label="Payment Type" [required]="true">
                <gx-multi-input-dropdown [(value)]="manualPaymentInfo.PaymentTypeId" [required]="true"
                    categoryKey="PaymentType" [options]="lookups" [excludedOptions]="[26]">
                </gx-multi-input-dropdown>
            </gx-labeled-input>

            <gx-button (click)="applyManualPaymentAsync()">Apply
            </gx-button>

            <gx-button (click)="clearPayment()">Cancel
            </gx-button>
        </div>
    </div>
</div>