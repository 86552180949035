<div class="name">
    {{ angler.FirstName }}
    {{ angler.LastName }}
</div>

<div class="captainOwner">
    {{ angler.IsCaptain ? 'Captain' : '' }}
    {{ angler.IsCaptain && angler.IsOwner ? '/' : ' '}}
    {{ angler.IsOwner ? 'Owner' : ''}}
</div>

<div class="status">
    {{ angler.StatusClassification }}
</div>

<div class="participantRelease">
    <gx-text-input
            [tooltip]="'Angler: Enter your first and last name\n(<b>' + angler.FirstName + ' ' + angler.LastName + '</b>) to sign the release.'"
            [(value)]="angler.ReleaseSignature"
            [required]="true"
            (click)="$event.stopPropagation()"
            (valueChange)="updateProperty()"
    ></gx-text-input>
</div>

<button (click)="editAngler()">Edit</button>
<button (click)="deleteAngler()">Delete</button>
