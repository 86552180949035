<div class="footer">
  <gx-icon-button
    [icon]="fontSolid.faQuestion"
    text="Help"
    [lineBreakAfterIcon]="true"
    (buttonClick)="helpClick.emit()"
  ></gx-icon-button>
  <gx-icon-button
    [icon]="fontSolid.faTimes"
    text="Close"
    [lineBreakAfterIcon]="true"
    (buttonClick)="closeClick.emit()"
  ></gx-icon-button>

  <div class="spacer"></div>

  <gx-icon-button [visible]="!!previousStep"
    [icon]="fontSolid.faArrowLeft"
    text="Previous"
    [lineBreakAfterIcon]="true"
    (buttonClick)="previousClick.emit()"
  ></gx-icon-button>
  <gx-icon-button *ngIf="!!nextStep"
    [enabled]="!!enableNext"
    [icon]="fontSolid.faArrowRight"
    text="Next"
    [lineBreakAfterIcon]="true"
    (buttonClick)="nextClick.emit()"
  ></gx-icon-button>
  <gx-icon-button *ngIf="!nextStep"
    [enabled]="!!enableNext"
    [icon]="fontSolid.faSignOutAlt"
    text="Complete"
    [lineBreakAfterIcon]="true"
    (buttonClick)="nextClick.emit()"
  ></gx-icon-button>
</div>
