<div *ngIf="isLoaded">
    <header>
        <img class="logo" src="/app/assets/images/bluewater-logo.png" />
        <gx-icon-button [icon]="fontSolid.faSignOutAlt" class="signout" text="Exit" (buttonClick)="logout()">
        </gx-icon-button>
    </header>
    <main *ngIf="passwordChangeInProgress">
        <gx-theme-banner>
            Change Password
        </gx-theme-banner>
        New Password: <gx-text-input type="password" [(value)]="newPassword" [required]="true"></gx-text-input>
        <div class="spacer"></div>
        <div>
            <gx-icon-button [icon]="fontSolid.faBan" text="Cancel" [lineBreakAfterIcon]="true"
                (buttonClick)="passwordChangeInProgress=false"></gx-icon-button>

            <gx-icon-button [icon]="fontSolid.faLock" text="Change Password" [lineBreakAfterIcon]="true"
                (buttonClick)="changePassword(newPassword)"></gx-icon-button>
        </div>

    </main>
    <main *ngIf="!passwordChangeInProgress">
        <gx-theme-banner *ngIf="teamTourneyList.length">
            Current Registrations
        </gx-theme-banner>

        <gx-theme-card *ngFor="let item of teamTourneyList" [title]="item.team.TournamentDescription"
            actionTitle="Edit Registration" (onAction)="navigateToBoatScreen(item.team.TournamentId)">
            {{ item.tournament.Description }}<br />
            {{ item.tournament.StartDate | gxSysdate }}<br />
            <b>{{ item.team.Summary }}</b>
        </gx-theme-card>

        <div class="spacer" *ngIf="teamTourneyList.length"></div>

        <gx-theme-banner *ngIf="tournamentList.length || !teamTourneyList.length">
            Register Now!
        </gx-theme-banner>

        <section class="no-tournaments" *ngIf="!teamTourneyList.length && !tournamentList.length">
            No Tournaments are Available for Registration at this time.
        </section>

        <gx-theme-card *ngFor="let tournament of tournamentList" [title]="tournament.SeasonDescription"
            actionTitle="Register Now" (onAction)="navigateToBoatScreen(tournament.TournamentId)">
            {{ tournament.Description }}<br />

            <span *ngIf="tournament.EarlyRegistrationDeadline | gxIsPast; then lateReg else earlyReg"></span>
            <ng-template #earlyReg>
                {{ tournament.EarlyRegistrationPrice | currency }}
                until {{tournament.EarlyRegistrationDeadline | gxSysdate}}
            </ng-template>
            <ng-template #lateReg>
                {{ tournament.RegistrationPrice | currency }}
            </ng-template>

            <details>
                Early: {{ tournament.EarlyRegistrationPrice }}; {{ tournament.EarlyRegistrationDeadline | gxSysdate }}
                Late: {{ tournament.RegistrationPrice }}; {{ tournament.RegistrationDeadline | gxSysdate }}
            </details>

        </gx-theme-card>

        <div class="spacer"></div>

    </main>
    <footer>
        <div class="footer">
            <gx-icon-button [icon]="fontSolid.faQuestion" text="Help" [lineBreakAfterIcon]="true"
                (buttonClick)="showHelp()"></gx-icon-button>
            <gx-icon-button *ngIf="!passwordChangeInProgress" [icon]="fontRegular.faStar" text="Change Password"
                [lineBreakAfterIcon]="true" (buttonClick)="startChangePassword()"></gx-icon-button>
        </div>
    </footer>
</div>