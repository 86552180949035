<div *ngIf="tournament">
    <bw-administration-header>
        Edit Tournament Waiver
    </bw-administration-header>
    <main>
        <h2>Tournament: {{ tournament.Description}}</h2>
        <hr/>
        <br/>

        <div>
            <div *ngFor="let document of documents"
                 (click)="selectDocument(document)"
                 [ngClass]="{
                  documentItem: true,
                  selected: selectedWaiver === document,
                  unselected: selectedWaiver !== document}">
                <div class="document-select">
                    <fa-icon class="selected-icon" [icon]="fontSolid.faCheck"></fa-icon>
                    <fa-icon class="unselected-icon" [icon]="fontRegular.faSquare"></fa-icon>
                </div>
                <div>
                    {{ document }}
                </div>
            </div>

        </div>

    </main>
    <footer>
        <gx-icon-button
                [icon]="fontSolid.faDoorClosed"
                text="Save and Close"
                (buttonClick)="close()"
        ></gx-icon-button>
    </footer>
</div>
