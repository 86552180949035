import {BrowserModule}           from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule}                from '@angular/core';
import {HttpClientModule}        from '@angular/common/http';
import {StoreModule}             from '@ngrx/store';
import {FormsModule}             from '@angular/forms';

import * as core          from '@gx/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent}     from './app.component';

import {
    FontAwesomeModule,
    FaIconLibrary
}                                from '@fortawesome/angular-fontawesome';
import {faSquare, faCheckSquare} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare as farSquare,
    faCheckSquare as farCheckSquare
}                                from '@fortawesome/free-regular-svg-icons';
import {
    faStackOverflow,
    faGithub,
    faMedium
}                                from '@fortawesome/free-brands-svg-icons';

import * as components             from './components';
import {RegistrationService}       from './services/registration.service';
import {GxSysdatePipe}             from './core/pipes/gx-sysdate.pipe';
import {GxPropertyEqualFilterPipe} from './core/pipes/gx-property-filter.pipe';
import {CommonModule}              from '@angular/common';

import {ToastrModule}                  from 'ngx-toastr';
import {GxDatePastPipe}                from './core/pipes/gx-is-past.pipe';
import { FileManagerComponent }        from './components/administration/file-manager/file-manager.component';
import {EditTournamentWaiverComponent} from './components/administration/edit-tournament-waiver/edit-tournament-waiver.component';
import { EditTournamentComponent } from './components/administration/edit-tournament/edit-tournament.component';

@NgModule({
    declarations: [
        AppComponent,
        ...core.components,
        ...components.tournamentComponents,
        GxSysdatePipe,
        GxPropertyEqualFilterPipe,
        GxDatePastPipe,
        EditTournamentComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        ToastrModule.forRoot(),
        StoreModule.forRoot({}, {}),
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private library: FaIconLibrary, private registrationService: RegistrationService) {
        library.addIcons(
            faSquare,
            faCheckSquare,
            farSquare,
            farCheckSquare,
            faStackOverflow,
            faGithub,
            faMedium
        );
    }
}
