import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gx-labeled-input',
  templateUrl: './gx-labeled-input.component.html',
  styleUrls: ['./gx-labeled-input.component.scss'],
})
export class GxLabeledInputComponent implements OnInit {
  @Input() label = '';
  @Input() required = false;

  ngOnInit(): void {}
}
